import React from "react";
import { Outlet } from "react-router-dom";
import styled from "@emotion/styled";
import { CssBaseline, useTheme } from "@mui/material";
import GlobalStyle from "../components/GlobalStyle";

interface AuthCoverType {
  children?: React.ReactNode;
}

const Root = styled.div`
  background: ${(props) => props.theme.palette.background.default};
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
`;

const Centered = styled.div`
  padding: ${(props) => props.theme.spacing(4)};
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  flex-direction: column;
  max-width: 480px;
`;

const AuthCover: React.FC<AuthCoverType> = ({ children }) => {

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Centered>
        {children}
        <Outlet />
      </Centered>
    </Root>
  );
};

export default AuthCover;

import { DefJsonResponse } from "@/api/buynow/types";

export const hiddenColumns = ["restriction","demo", "manufacturer_name"]
export const tabsOptions = ['setup', 'items', 'look', 'listing', 'pos'];
export const lookBaseKeys: (keyof DefJsonResponse)[] = ["style", "brand", "avgRating", "label"];
export const listBaseKeys: (keyof DefJsonResponse)[] = [
  "posBranding", 
  "layoutOffline", 
  "price", 
  "ratings", 
  "stock", 
  "sorting", 
  "sortingPreferred", 
  "label"
];
export const tabsWithReset = [2];
export const colorFields = [
  "btnColor",
  "btnTextColor",
  "primaryColor",
  "textColor",
  "headerTextColor",
];

export const validationRules: {
  [key: string]: { pattern: RegExp; message: string };
} = {
  sku: { 
    pattern: /^\d{8}$/, 
    message: "pages.buyNowComponent.editModal.validation.sku",
  },
  width: { 
    pattern: /^(0|[1-9]\d*)$/, 
    message: "pages.buyNowComponent.editModal.validation.width",
  },
  height: { 
    pattern: /^(0|[1-9]\d*)$/, 
    message: "pages.buyNowComponent.editModal.validation.height",
  },
  url: {
    pattern:
      /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/,
    message: "pages.buyNowComponent.editModal.validation.url",
  },
  color: {
    pattern: /^#([A-Fa-f0-9]{6})$/,
    message: "pages.buyNowComponent.editModal.validation.height",
  },
};

export interface DefLocalConfig {
  posBranding: number;
  layoutOffline: number;
  price: boolean;
  ratings: boolean;
  stock: boolean;
  sorting: number[];
  sortingPreferred: number;
  label: {
    grouping: number;
  };
}

/*export const defLocalContentConfig: DefLocalConfig = {
  posBranding: 1,
  layoutOffline: 1,
  price: true,
  ratings: true,
  stock: true,
  sorting: [1],
  sortingPreferred: 2,
  label: {
    grouping: 1,
  },
};

export const defLocalSocialConfig: DefLocalConfig = {
  posBranding: 2,
  layoutOffline: 2,
  price: true,
  ratings: true,
  stock: true,
  sorting: [1],
  sortingPreferred: 2,
  label: {
    grouping: 1,
  },
};*/

import React, { useEffect, useState, useMemo } from "react";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { useTabsContext } from "@/contexts/TabsContext";
import { validationRules, lookBaseKeys, colorFields } from "../consts";
import { useTranslation } from "react-i18next";
import { DefJsonResponse } from "@/api/buynow/types";
import { deepMerge } from "@/utils/deepMerge";
import { TabsProps } from "../types";
import tinycolor from "tinycolor2";

const LookTab: React.FC<TabsProps> = ({
  reset = false,
  setReset,
}) => {
  const {
    configs,
    setConfigs,
    defaultConfigIndex,
    setValidationErrors,
    setWarnings,
    warnings,
    defaultConfig,
  } = useTabsContext();

  const { t } = useTranslation();

  const [focusedInput, setFocusedInput] = useState<string>("");

  const lookDefault = useMemo(() => {
    if (!defaultConfig) return {};

    const filteredConfig = lookBaseKeys.reduce((acc, key) => {
      if (key in defaultConfig) {
        acc[key] = defaultConfig?.[key] as never;
      }
      return acc;
    }, {} as DefJsonResponse);

    return filteredConfig;
  }, [defaultConfig]);

  const currentConfig = useMemo(() => {
    const c = configs[defaultConfigIndex].config.config;

    const filteredConfig = lookBaseKeys.reduce((acc, key) => {
      if (key in c) {
        acc[key] = c?.[key];
      }
      return acc;
    }, {} as DefJsonResponse);

    return filteredConfig;
  }, [configs, defaultConfigIndex]);

  const [configState, setConfigState] = useState(deepMerge(lookDefault, currentConfig));

  useEffect(() => {
    setConfigState(deepMerge(lookDefault, currentConfig));
  }, [lookDefault, currentConfig]);

  const handleReset = () => {
    setConfigState((prevState) => ({
      ...prevState,
      ...lookDefault,
    }));

    setConfigs((prevConfigs) =>
      prevConfigs.map((config, idx) =>
        idx === defaultConfigIndex
          ? {
              ...config,
              config: {
                ...config.config,
                config: {
                  ...config.config.config,
                  ...lookDefault,
                },
              },
            }
          : config
      )
    );

    setWarnings({});
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      lookTab: {},
    }));
  };

  useEffect(() => {
    if(reset && setReset) {
      handleReset();
      setReset(false);
    }
  }, [reset]);

  const validateField = (field: string, value: string) => {
    const updatedWarnings = { ...warnings };

    if (field === "logoUrl") {
      if (value.trim() === "") {
        delete updatedWarnings[field];
      } else if (!validationRules.url.pattern.test(value)) {
        updatedWarnings[field] = t(validationRules.url.message);
      } else {
        delete updatedWarnings[field];
      }
    } else if (colorFields.includes(field)) {
      if (!validationRules.color.pattern.test(value)) {
        updatedWarnings[field] = t(validationRules.color.message);
      } else {
        delete updatedWarnings[field];
      }
    } else if (field === "width" || field === "height") {
      const rule = validationRules[field];
      if (!rule.pattern.test(value)) {
        updatedWarnings[field] = t(rule.message);
      } else {
        delete updatedWarnings[field];
      }
    }

    setWarnings(updatedWarnings);
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      lookTab: { ...updatedWarnings },
    }));
  };

  const updateConfigStyle = (config: any, field: string, value: any) => ({
    ...config,
    config: {
      ...config.config,
      config: {
        ...config.config?.config,
        style: {
          ...config.config?.config?.style,
          [field]: value,
        },
      },
    },
  });

  const updateConfigBrand = (config: any, field: string, value: any) => ({
    ...config,
    config: {
      ...config.config,
      config: {
        ...config.config?.config,
        brand: {
          ...config.config?.config?.brand,
          [field]: value,
        },
      },
    },
  });

  const updateConfigLabel = (config: any, field: string, value: any) => ({
    ...config,
    config: {
      ...config.config,
      config: {
        ...config.config?.config,
        label: {
          ...config.config?.config?.label,
          [field]: value,
        },
      },
    },
  });

  const updateConfigAvgRating = (config: any, value: boolean) => ({
    ...config,
    config: {
      ...config.config,
      config: {
        ...config.config?.config,
        avgRating: value,
      },
    },
  });

  const updateConfigLogoFormat = (config: any, field: string, value: any) => ({
    ...config,
    config: {
      ...config.config,
      config: {
        ...config.config?.config,
        brand: {
          ...config.config?.config?.brand,
          logoFormat: {
            ...config.config?.config?.brand?.logoFormat,
            [field]: value,
          },
        },
      },
    },
  });

  const alignmentOptions = [
    { label: t("pages.buyNowComponent.editModal.look.left"), name: "left" },
    { label: t("pages.buyNowComponent.editModal.look.center"), name: "center" },
    { label: t("pages.buyNowComponent.editModal.look.right"), name: "right" },
  ];

  const handleChange = (field: string, value: any) => {
    setConfigState((prevState) => ({
      ...prevState,
      [field]: value,
    }));

    setConfigs((prevConfigs) =>
      prevConfigs.map((config, idx) =>
        idx === defaultConfigIndex
          ? updateConfigAvgRating(config, value)
          : config
      )
    );
  };

  const handleStyleChange = (styleField: string, value: any) => {
    setConfigState((prevState) => ({
      ...prevState,
      style: {
        ...prevState.style,
        [styleField]: value,
      },
    }));

    validateField(styleField, value);

    setConfigs((prevConfigs) =>
      prevConfigs.map((config, idx) =>
        idx === defaultConfigIndex
          ? updateConfigStyle(config, styleField, value)
          : config
      )
    );
  };

  const handleLabelChange = (labelField: string, value: boolean) => {
    setConfigState((prevState) => ({
      ...prevState,
      label: {
        ...prevState.label,
        [labelField]: value,
      },
    }));
    setConfigs((prevConfigs) =>
      prevConfigs.map((config, idx) =>
        idx === defaultConfigIndex
          ? updateConfigLabel(config, labelField, value)
          : config
      )
    );
  };

  const handleBrandChange = (brandField: string, value: any) => {
    setConfigState((prevState) => ({
      ...prevState,
      brand: {
        ...prevState.brand,
        [brandField]: value,
      },
    }));

    validateField(brandField, value);

    setConfigs((prevConfigs) =>
      prevConfigs.map((config, idx) =>
        idx === defaultConfigIndex
          ? updateConfigBrand(config, brandField, value)
          : config
      )
    );
  };

  const handleLogoFormatChange = (formatField: string, value: any) => {
    if(formatField === "width" || formatField === "height") {
      if(isNaN(Number(value))) {
        return
      } else {
        value = value === "" ? value : Number(value);
      }
    } 
    setConfigState((prevState) => ({
      ...prevState,
      brand: {
        ...prevState.brand,
        logoFormat: {
          ...prevState.brand?.logoFormat,
          [formatField]: value,
        },
      },
    }));

    validateField(formatField, value);

    setConfigs((prevConfigs) =>
      prevConfigs.map((config, idx) =>
        idx === defaultConfigIndex
          ? updateConfigLogoFormat(config, formatField, value)
          : config
      )
    );
  };

  const getColorInput = (value: string, inputName: string) => {
    if (inputName === focusedInput) return value;
    const pattern = /^#([A-Fa-f0-9]{6})$/;
    const tc = tinycolor(value);
    return tc.isValid() && pattern.test(value) ? tc.toHexString() : value;
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="subtitle1" mb={2}>
          {" "}
          {t("pages.buyNowComponent.editModal.look.colorSettings")}
      </Typography>
      <Box display="flex" alignItems="flex-start" sx={{ mb: 2 }}>
        <input
          type="color"
          value={getColorInput(configState.style?.primaryColor, "primaryColor")}
          onChange={(e) => handleStyleChange("primaryColor", e.target.value)}
          style={{
            width: "51px",
            height: "55px",
            border: "none",
            padding: 0,
          }}
        />
        <TextField
          label={t("pages.buyNowComponent.editModal.look.primaryColor")}
          value={getColorInput(configState.style?.primaryColor, "primaryColor")}
          onChange={(e) => handleStyleChange("primaryColor", e.target.value)}
          fullWidth
          sx={{ flex: 1, ml: 2 }}
          error={!!warnings["primaryColor"]}
          helperText={warnings["primaryColor"] || ""}
          onFocus={() => setFocusedInput("primaryColor")}
          onBlur={() => setFocusedInput((prev) => prev === "primaryColor" ? "" : prev)}
        />
      </Box>

      <Typography variant="subtitle1" my={2}>
        {t("pages.buyNowComponent.editModal.look.textColor")}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box display="flex" alignItems="flex-start">
            <input
              type="color"
              value={getColorInput(configState.style?.headerTextColor, "headerTextColor")}
              onChange={(e) =>
                handleStyleChange("headerTextColor", e.target.value)
              }
              style={{
                width: "51px",
                height: "55px",
                border: "none",
                padding: 0,
              }}
            />
            <TextField
              label={t("pages.buyNowComponent.editModal.look.header")}
              value={getColorInput(configState.style?.headerTextColor, "headerTextColor")}
              onChange={(e) =>
                handleStyleChange("headerTextColor", e.target.value)
              }
              fullWidth
              sx={{ flex: 1, ml: 2 }}
              error={!!warnings["headerTextColor"]}
              helperText={warnings["headerTextColor"] || ""}
              onFocus={() => setFocusedInput("headerTextColor")}
              onBlur={() => setFocusedInput((prev) => prev === "headerTextColor" ? "" : prev)}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" alignItems="flex-start">
            <input
              type="color"
              value={getColorInput(configState.style?.textColor, "textColor")}
              onChange={(e) => handleStyleChange("textColor", e.target.value)}
              style={{
                width: "51px",
                height: "55px",
                border: "none",
                padding: 0,
              }}
            />
            <TextField
              label={t("pages.buyNowComponent.editModal.look.listing")}
              value={getColorInput(configState.style?.textColor, "textColor")}
              onChange={(e) => handleStyleChange("textColor", e.target.value)}
              fullWidth
              sx={{ flex: 1, ml: 2 }}
              error={!!warnings["textColor"]}
              helperText={warnings["textColor"] || ""}
              onFocus={() => setFocusedInput("textColor")}
              onBlur={() => setFocusedInput((prev) => prev === "textColor" ? "" : prev)}
            />
          </Box>
        </Grid>
      </Grid>

      <Typography variant="subtitle1" my={2}>
        {t("pages.buyNowComponent.editModal.look.button")}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box display="flex" alignItems="flex-start">
            <input
              type="color"
              value={getColorInput(configState.style?.btnTextColor, "btnTextColor")}
              onChange={(e) =>
                handleStyleChange("btnTextColor", e.target.value)
              }
              style={{
                width: "51px",
                height: "55px",
                border: "none",
                padding: 0,
              }}
            />
            <TextField
              label={t("pages.buyNowComponent.editModal.look.text")}
              value={getColorInput(configState.style?.btnTextColor, "btnTextColor")}
              onChange={(e) =>
                handleStyleChange("btnTextColor", e.target.value)
              }
              fullWidth
              sx={{ flex: 1, ml: 2 }}
              error={!!warnings["btnTextColor"]}
              helperText={warnings["btnTextColor"] || ""}
              onFocus={() => setFocusedInput("btnTextColor")}
              onBlur={() => setFocusedInput((prev) => prev === "btnTextColor" ? "" : prev)}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" alignItems="flex-start">
            <input
              type="color"
              value={getColorInput(configState.style?.btnColor, "btnColor")}
              onChange={(e) => handleStyleChange("btnColor", e.target.value)}
              style={{
                width: "51px",
                height: "55px",
                border: "none",
                padding: 0,
              }}
            />
            <TextField
              label={t("pages.buyNowComponent.editModal.look.background")}
              value={getColorInput(configState.style?.btnColor, "btnColor")}
              onChange={(e) => handleStyleChange("btnColor", e.target.value)}
              fullWidth
              sx={{ flex: 1, ml: 2 }}
              error={!!warnings["btnColor"]}
              helperText={warnings["btnColor"] || ""}
              onFocus={() => setFocusedInput("btnColor")}
              onBlur={() => setFocusedInput((prev) => prev === "btnColor" ? "" : prev)}
            />
          </Box>
        </Grid>
      </Grid>

      <Divider sx={{ mt: 4, mb: 2 }} />

      <Typography variant="subtitle1" mb={2}>
        {t("pages.buyNowComponent.editModal.look.brandLogo")}
      </Typography>
      <TextField
        label={t("pages.buyNowComponent.editModal.look.url")}
        placeholder="http"
        fullWidth
        sx={{ mb: 2 }}
        value={configState.brand?.logoUrl}
        onChange={(e) => handleBrandChange("logoUrl", e.target.value)}
        error={!!warnings["logoUrl"]}
        helperText={warnings["logoUrl"] || ""}
      />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            label={t("pages.buyNowComponent.editModal.look.width")}
            fullWidth
            value={configState.brand?.logoFormat?.width}
            onChange={(e) =>
              handleLogoFormatChange("width", e.target.value)
            }
            error={!!warnings["width"]}
            helperText={warnings["width"] || ""}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label={t("pages.buyNowComponent.editModal.look.height")}
            fullWidth
            value={configState.brand?.logoFormat?.height}
            onChange={(e) =>
              handleLogoFormatChange("height", e.target.value)
            }
            error={!!warnings["height"]}
            helperText={warnings["height"] || ""}
          />
        </Grid>
      </Grid>

      <Typography variant="subtitle1" mt={2}>
        {t("pages.buyNowComponent.editModal.look.alignment")}
      </Typography>
      <Box display="flex" justifyContent="space-between">
        {alignmentOptions.map((option) => (
          <FormControlLabel
            key={option.name}
            control={
              <Checkbox
                checked={configState.brand?.logoFormat?.align === option.name}
                onChange={() => handleLogoFormatChange("align", option.name)}
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<RadioButtonCheckedIcon />}
                sx={{
                  color: "primary.main",
                  "&.Mui-checked": {
                    color: "primary.main",
                  },
                  borderRadius: "100%",
                }}
              />
            }
            label={option.label}
          />
        ))}
      </Box>

      <Divider sx={{ my: 2 }} />

      <Typography variant="subtitle1">
        {" "}
        {t("pages.buyNowComponent.editModal.look.headerSettings")}
      </Typography>
      <Box display="flex" flexWrap="wrap">
        <Box display="flex" alignItems="center">
          <Switch
            checked={configState.label?.header}
            onChange={(e) => handleLabelChange("header", e.target.checked)}
          />
          <Typography>
            ‘BUY NOW’ {t("pages.buyNowComponent.editModal.look.badge")}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Switch
            checked={configState.label?.headerIcon}
            onChange={(e) => handleLabelChange("headerIcon", e.target.checked)}
          />
          <Typography>
            {t("pages.buyNowComponent.editModal.look.showIcon")}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Switch
            checked={configState.avgRating}
            onChange={(e) => handleChange("avgRating", e.target.checked)}
          />
          <Typography>
            {t("pages.buyNowComponent.editModal.look.showAverageRating")}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default LookTab;

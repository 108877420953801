import { Label } from "@/api/setup/types";
import { Box, Typography } from "@mui/material";
import useCustomTableUtils from "@/hooks/useColumnsData/utils";
import { useEffect, useState } from "react";
import AddLabelsModal from "../AddLablesModal";
import useData from "@/hooks/auth/useData";
import { useLocation } from "react-router-dom";

type LabelsCellProps = {
  labels: {
    id: number;
    labels: Label[];
  };
  params: {
    row: {
      title?: string;
      keyword?: string;
    };
  };
};

const LabelsCell = ({ labels, params }: LabelsCellProps) => {
  const { na } = useCustomTableUtils();

  const { setupLabelsApi } = useData();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedLabel, setSelectedLabel] = useState<Label[] | null>(
    labels.labels
  );

  useEffect(() => {
    setupLabelsApi.execute();
  }, []);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

 


  return (
    <Box width={"100%"} height={"100%"}>
      <Box
        onClick={handleOpen}
        width={"100%"}
        height={"100%"}
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
      >
        {(selectedLabel?.length ?? 0) > 0 ? (
          selectedLabel?.map((label) => (
            <Box
              key={label.label_id}
              display="flex"
              alignItems="center"
              sx={{
                mr: "8px",
                cursor: "pointer",
              }}
            >
              <Box
                sx={{
                  width: 14,
                  height: 14,
                  borderRadius: "50%",
                  backgroundColor: label.label_color,
                  mr: "4px",
                }}
              />
              <Typography fontWeight={500}>{label.label_name}</Typography>
            </Box>
          ))
        ) : (
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            sx={{ cursor: "pointer" }}
          >
            {na}
          </Box>
        )}
      </Box>
      <AddLabelsModal
        anchorEl={anchorEl}
        handleClose={handleClose}
        selectedLabel={selectedLabel}
        setSelectedLabel={setSelectedLabel}
        keywordsId={labels.id}
        setupLabelsApi={setupLabelsApi}
        isKeyword={Boolean(params.row.keyword)}
      />
    </Box>
  );
};

export default LabelsCell;
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Button,
  TextField,
  Alert,
  FormControl,
  CircularProgress,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useData from "@/hooks/auth/useData";
import { CollaborationSelect } from "@/components/CollaborationSelect";
import CustomAutocomplete from "@/components/CustomAutocomplete";
import { CreateMarketModalProps, Option } from "./types";

const CreateMarketModal = ({
  open,
  onClose,
  onSuccess,
  selectedRow,
  listApi,
}: CreateMarketModalProps) => {
  const { t } = useTranslation();
  const {
    manufacturersApi,
    allBrandsApi,
    productsApi,
    setupMarketsCreateApi,
    setupMarketUpdateApi,
  } = useData();

  const [loading, setLoading] = useState(false);
  const [market, setMarket] = useState("");
  const [comment, setComment] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [selectedManufacturer, setSelectedManufacturer] = useState<number[]>(
    []
  );
  const [selectedBrands, setSelectedBrands] = useState<number[]>([]);
  const [selectedSubBrands, setSelectedSubBrands] = useState<number[]>([]);

  const [selectedProduct, setSelectedProduct] = useState<number[]>([]);

  const [marketError, setMarketError] = useState(false);
  const [productError, setProductError] = useState(false);
  const [maxProductError, setMaxProductError] = useState(false);
  const [maxBrandError, setMaxBrandError] = useState(false);

  useEffect(() => {
    if (!!selectedRow?.children.length) {
      const m = Array.from(
        new Set(selectedRow.children.map((item) => item.manufacturer_id))
      );

      const brands = Array.from(
        new Set(selectedRow.children.map((item) => item.brand_id))
      );

      const filteredBrands =
        allBrandsApi.data?.filter((brand) => brands.includes(brand.ID)) || [];

      const filteredSubBrandsBrands =
        allBrandsApi.data?.filter((brand) => brands.includes(brand.ID)) || [];

      const b = Array.from(
        new Set(
          filteredBrands
            .map((item) => {
              return item.ID_parent === item.ID ? item.ID_parent : null;
            })
            .filter(Boolean)
        )
      );

      const sb = Array.from(
        new Set(
          filteredSubBrandsBrands
            .filter((item) => item.ID_parent !== item.ID)
            .map((item) => item.ID)
        )
      );

      const p = Array.from(
        new Set(selectedRow.children.map((item) => item.product_id))
      );

      setSelectedBrands(b as number[]);
      setSelectedSubBrands(sb);
      setSelectedManufacturer(m);
      setSelectedProduct(p);
    }
  }, [selectedRow?.children, allBrandsApi.data]);

  useEffect(() => {
    if (selectedRow?.name) {
      setMarket(selectedRow.name);
    }
    if (selectedRow?.comment) {
      setComment(selectedRow.comment);
    }
  }, [selectedRow]);

  useEffect(() => {
    if (!!selectedManufacturer.length) {
      allBrandsApi.execute({
        m: selectedManufacturer,
      });
    }
  }, [selectedManufacturer]);

  useEffect(() => {
    if (
      selectedManufacturer.length &&
      (selectedBrands.length || selectedSubBrands.length)
    ) {
      productsApi.execute({
        m: selectedManufacturer,
        b: selectedBrands,
        sb: selectedSubBrands,
      });
    }
  }, [selectedBrands, selectedSubBrands, selectedManufacturer]);

  const handleClose = () => {
    setMarket("");
    setComment("");
    setSelectedManufacturer([]);
    setSelectedBrands([]);
    setSelectedSubBrands([]);
    setSelectedProduct([]);
    setError(null);
    setMarketError(false);
    setProductError(false);
    onClose();
  };

  const handleSubmit = async () => {
    setLoading(true);

    const hasMarketError = !market;
    const hasProductError = !selectedProduct.length;

    setMarketError(hasMarketError);
    setProductError(hasProductError);

    if (hasMarketError || hasProductError || maxProductError || maxBrandError) {
      setLoading(false);
      return;
    }

    try {
      if (selectedRow) {
        await setupMarketUpdateApi.execute({
          id: selectedRow.id,
          comment,
          name: market,
          products: selectedProduct,
        });
      } else {
        await setupMarketsCreateApi.execute({
          products: selectedProduct,
          comment,
          name: market,
        });
      }

      setError(null);
      setMarketError(false);
      setProductError(false);
      setMaxProductError(false);
      setMaxBrandError(false);
      onSuccess();
      onClose();
      setMarket("");
      setComment("");
      await listApi.execute(undefined, false);
      setLoading(false);
    } catch (error) {
      setError(t("pages.labels.alerts.createFailed"));
      setLoading(false);
    }
  };

  const handleManufacturerChange = (newValue: Option[]) => {
    const selectedValues = newValue.map((item) => Number(item.value));
    setSelectedManufacturer(selectedValues);
    setSelectedBrands([]);
    setSelectedSubBrands([]);
    setSelectedProduct([]);

    if (selectedValues.length > 0) {
      allBrandsApi.execute({ m: selectedValues });
    }
  };

  const handleBrandChange = (newValue: Option[]) => {
    const selectedValues = newValue.map((item) => Number(item.value));

    if (selectedValues.length > 5) {
      setMaxBrandError(true);
      return;
    } else {
      setMaxBrandError(false);
    }

    const brands = selectedValues.filter((id) => {
      const brand = allBrandsApi?.data?.find(
        (brand) => brand.ID_parent === brand.ID && brand.ID_parent === id
      );
      return brand ? true : false;
    });

    const subBrands = selectedValues.filter((id) => {
      const subBrand = allBrandsApi?.data?.find(
        (brand) => brand.ID_parent !== brand.ID && brand.ID === id
      );
      return subBrand ? true : false;
    });

    setSelectedBrands(brands);
    setSelectedSubBrands(subBrands);

    setSelectedProduct([]);

    if (brands.length || subBrands.length) {
      productsApi.execute({
        m: selectedManufacturer,
        b: brands,
        sb: subBrands,
      });
    }
  };

  const handleProductChange = (newValue: Option[]) => {
    const selectedValues = newValue?.map((item) => Number(item.value));
    setProductError(false);

    if (selectedValues.length > 20) {
      setMaxProductError(true);
    } else {
      setMaxProductError(false);
      setSelectedProduct(selectedValues);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        {selectedRow
          ? t("pages.markets.modal.update")
          : t("pages.markets.modal.create")}
      </DialogTitle>
      <DialogContent sx={{ overflow: "hidden" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <TextField
              variant="standard"
              margin="dense"
              InputLabelProps={{ shrink: true }}
              size="small"
              label={t("filters.market")}
              placeholder={t("pages.markets.modal.name") as string}
              fullWidth
              required
              value={market}
              onChange={(e) => {
                setMarket(e.target.value);
                setMarketError(false);
              }}
              error={marketError}
              helperText={marketError ? t("general.required") : ""}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CollaborationSelect disabled />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <FormControl fullWidth margin="dense" size="small">
              <CustomAutocomplete
                label={t("filters.manufacturer")}
                textFieldShrink={true}
                textFieldVariant="standard"
                width={178.66}
                hasAllOption={false}
                value={selectedManufacturer?.map((id) => ({
                  value: id.toString(),
                  label:
                    manufacturersApi?.manufacturers.data?.find(
                      (item) => item.ID === id
                    )?.NAME || "",
                }))}
                onChange={handleManufacturerChange}
                options={
                  manufacturersApi?.manufacturers.data?.map((item) => ({
                    value: item.ID.toString(),
                    label: item.NAME,
                  })) || []
                }
                multiSelect
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth margin="dense" size="small">
              <CustomAutocomplete
                label={t("filters.brand")}
                textFieldShrink={true}
                error={maxBrandError}
                helperText={
                  maxBrandError
                    ? (t("pages.markets.modal.maxBrands") as string)
                    : ""
                }
                textFieldVariant="standard"
                width={178.66}
                hasAllOption={false}
                value={[...selectedBrands, ...selectedSubBrands]?.map((id) => ({
                  value: id.toString(),
                  label:
                    allBrandsApi?.data?.find((item) => item.ID === id)?.BRAND ||
                    "",
                  subBrands: allBrandsApi?.data?.filter(
                    (item) => item.ID === id
                  ),
                }))}
                onChange={handleBrandChange}
                options={
                  allBrandsApi?.data?.map((item) => ({
                    value: item.ID.toString(),
                    label: item.BRAND,
                  })) || []
                }
                multiSelect
                disabled={!selectedManufacturer.length}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth margin="dense" size="small">
              <CustomAutocomplete
                label={t("filters.product")}
                textFieldShrink={true}
                textFieldVariant="standard"
                width={178.66}
                error={productError || maxProductError}
                helperText={
                  maxProductError
                    ? (t("pages.markets.modal.maxProducts") as string)
                    : productError
                    ? (t("pages.markets.modal.selfProduct") as string)
                    : ""
                }
                hasAllOption={false}
                value={selectedProduct?.map((id) => ({
                  value: id.toString(),
                  label:
                    productsApi?.data?.find((item) => item.PID === id)?.NAME ||
                    "",
                }))}
                onChange={handleProductChange}
                options={
                  productsApi?.data?.map((item) => ({
                    value: item.PID.toString(),
                    label: item.NAME + " - " + item.PZN,
                  })) || []
                }
                multiSelect
                disabled={
                  !selectedManufacturer.length ||
                  (!selectedBrands.length && !selectedSubBrands.length) ||
                  maxBrandError
                }
              />
            </FormControl>
          </Grid>
        </Grid>
        <TextField
          variant="outlined"
          size="small"
          margin="dense"
          label={t("pages.markets.modal.comment")}
          fullWidth
          multiline
          rows={4}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </DialogContent>
      <DialogActions
        sx={{ justifyContent: "flex-start", paddingLeft: 6, paddingRight: 6 }}
      >
        <Button
          onClick={handleClose}
          size="large"
          sx={{
            "&:focus": {
              outline: "2px solid #1976d2",
            },
          }}
        >
          {t("general.cancel")}
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          size="large"
          disabled={loading}
          sx={{
            "&:focus": {
              outline: "2px solid #1976d2",
            },
          }}
        >
          {loading ? <CircularProgress size={16} /> : t("general.save")}
        </Button>

        {error && (
          <Alert
            variant="filled"
            severity="error"
            sx={{ width: "100%", paddingTop: 1, paddingBottom: 1 }}
          >
            {error}
          </Alert>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CreateMarketModal;

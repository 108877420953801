const isObject = (item: any): item is Record<string, any> =>
    item && typeof item === "object" && !Array.isArray(item);
  
export const deepMerge = (target: Record<string, any>, source: Record<string, any>): Record<string, any> => {
    const output = { ...target };
    for (const key in source) {
        if (isObject(source[key])) {
            if (!(key in target)) {
                output[key] = source[key];
            } else {
                output[key] = deepMerge(target[key], source[key]);
            }
        } else {
            output[key] = source[key];
        }
    }
    return output;
};
import ContentContainer from "@/components/contentContainer";
import { MuiGridTable } from "@/components/tables/DataGridTable";
import { Paper, Button, Menu, MenuItem } from "@mui/material";
import {
  GridColDef,
  GridRowSelectionModel,
  GridToolbarContainer,
} from "@mui/x-data-grid-pro";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { LabelsResponse } from "../../../api/setup/types";
import { useColumns } from "@/hooks/useColumnsData";
import useData from "@/hooks/auth/useData";
import Snackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";
import { Alert } from "@mui/material";
import CreateLabelModal from "./CreateLabelModal";

const Labels = () => {
  const { t } = useTranslation();
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);
  const { selectedManufacturers, setupLabelsApi, setupLabelsUpdateApi, setupLabelsDeleteApi } =
    useData();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedRow, setSelectedRow] = useState<LabelsResponse>();
  const [alertError, setAlertError] = useState("")
  const [deletedIds, setDeletedIds] = useState<number[]>([])

  const manufacturerIds = useMemo(
    () => selectedManufacturers.map((m) => m.ID),
    [selectedManufacturers]
  );

  const handleMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    row: LabelsResponse
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = async () => {
    if (selectedRow) {
      try {
        const res = await setupLabelsDeleteApi.execute({
          ids: [selectedRow.ID],
        });
        if (res.deleted?.length) {
          setDeletedIds([...deletedIds, ...res.deleted])
        }
      } catch (error) {
        console.error(error)
        setAlertError(t("pages.labels.alerts.deleteFailed") || "Delete error")
        setOpenSnackbar(true);
      }
    }
    handleMenuClose();
  }

  const rows = useMemo(() => {
    return (setupLabelsApi.data || [])
      .filter((row) => !deletedIds.includes(row.ID))
      .map((row) => ({
        ...row,
        UPDATED: row.updated,
        id: row.ID,
      }));
  }, [setupLabelsApi.data, deletedIds]);

  useEffect(() => {
    if (!manufacturerIds.length) return;

    setupLabelsApi.execute({
      m: manufacturerIds,
    });
  }, [manufacturerIds]);

  const allColumns = useColumns();

  const columns: GridColDef<LabelsResponse>[] = [
    allColumns.labelColumn,
    allColumns.commentColumn,
    allColumns.itemsColumn,
    allColumns.typeColumn,
    allColumns.collaborationColumn,
    allColumns.ownerLabelsColumn,
    allColumns.updatedDateColumn,
    {
      ...allColumns.actionsColumn((params, event) => handleMenuClick(event, params.row)),
      //@ts-ignore
      getActions: (params) => {
        if (params.row.collaboration !== "private") {
          return [];
        }
        
        return allColumns.actionsColumn((params, event) =>
        handleMenuClick(event, params.row)
        //@ts-ignore
        ).getActions?.(params);
      },
    },
  ];

  const processRowUpdate = (newRow: LabelsResponse, oldRow: LabelsResponse) => {
    const oldKeywords = oldRow.keywords.map((k) => k.ID);
    const oldCategories = oldRow.categories.map((c) => c.ID);

    setupLabelsUpdateApi
      .execute({
        id: newRow.ID,
        name: newRow.name,
        comment: newRow.comment,
        keywords: oldKeywords,
        categories: oldCategories,
      })
      .catch((error) => {
        handleProcessRowUpdateError(error);
      });
    return newRow;
  };

  const handleCloseSnackbar = (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleProcessRowUpdateError = useCallback((error: Error) => {
    setAlertError(t("pages.labels.alerts.updateFailed") || "Update error")
    setOpenSnackbar(true);
  }, []);

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <ContentContainer>
      <Paper>
        <MuiGridTable
          rows={rows}
          columns={columns}
          onRowSelectionModelChange={(ids) => setSelectedRows(ids)}
          selectedRows={selectedRows}
          loading={setupLabelsApi?.isLoading}
          filterBarType={2}
          searchPlaceholder={t("pages.labels.search") as string}
          hasCheckboxes={false}
          title={t("pages.labels.title") as string}
          isError={setupLabelsApi.isError}
          error={setupLabelsApi.error}
          hasControls={true}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          onCreateClick={() => setOpenModal(true)}
        />
      </Paper>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={handleDelete}>
          {t("general.delete")}
        </MenuItem>
      </Menu>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: "100%" }}
        >
          {alertError}
        </Alert>
      </Snackbar>

      <CreateLabelModal
        open={openModal}
        listApi={setupLabelsApi}
        onClose={handleClose}
        manufacturerIds={manufacturerIds}
        onSuccess={async () => {
          await setupLabelsApi.execute({ m: manufacturerIds });
          handleClose();
        }}
      />
    </ContentContainer>
  );
};

export default Labels;

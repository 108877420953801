import { Config, ConfigImage } from '@/api/buynow/types';
import { v4 as uuidv4 } from 'uuid';
import { sanitize } from '@/utils/htmlText';

export const generatePrefilledEntries = (currentConfigDetails: Config, currentVariants: string[]) => {
  const allSKUs = new Set([
    ...currentVariants,
    ...(currentConfigDetails.config?.images?.map((img: ConfigImage) => img.sku) || []),
    ...(currentConfigDetails.config?.texts?.flatMap((txt: { sku: string | string[], text: string }) =>
      Array.isArray(txt.sku) ? txt.sku : [txt.sku]
    ) || []),
  ]);

  const prefilledEntries = Array.from(allSKUs).map((sku) => {
    const imageEntry = currentConfigDetails.config?.images?.find((img: ConfigImage) => img.sku === sku) || {};
    const textEntry = currentConfigDetails.config?.texts?.find((txt: { sku: string, text: string }) => Array.isArray(txt.sku) ? txt.sku.includes(sku) : txt.sku === sku)?.text || '';
    const description = sanitize(textEntry);

    return {
      id: uuidv4(),
      sku,
      image: imageEntry.image || '',
      width: imageEntry.imageFormat?.width || '',
      height: imageEntry.imageFormat?.height || '',
      description,
    };
  });
  return prefilledEntries;
};

export const getType = (value: number) => {
    return value === 1 ? 'BUY NOW Social' : 'BUY NOW Content';
}
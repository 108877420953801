import React from "react";
import { Menu as MenuDiv, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";

interface MenuProps {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  onEditClick: () => void;
}

const Menu: React.FC<MenuProps> = ({ anchorEl, onClose, onEditClick }) => {
  const { t } = useTranslation();
  return (
    <MenuDiv
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <MenuItem
        onClick={() => {
          onEditClick();
          onClose();
        }}
        sx={{ minWidth: "100px" }}
      >
        {t("general.edit")}
      </MenuItem>
    </MenuDiv>
  );
};

export default Menu;

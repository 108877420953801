// Layouts
import ErrorLayout from "@mira/layouts/Error";
import AuthLayout from "../layouts/Auth";
import AuthCoverLayout from "../layouts/AuthCover";
import DashboardLayout from "../layouts/Dashboard";

// Auth components
import Page404 from "@mira/pages/auth/Page404";
import AuthGuard from "../components/guards/AuthGuard";
import SignIn from "../pages/auth/SignIn";

import Analytics from "@/pages/analytics/Analytics";
import { VisibilityShareOfSearch } from "@/pages/visibility/ShareOfVoice";
import AiOptimizer from "@/pages/content/AiOptimizer";
import Content from "@/pages/content/contentBoard";
import { BuyNowTransactions } from "@/pages/buynow/transactions/index";
import RatingsAndReviews from "@/pages/content/RatingsAndReviews";
import Labels from "@/pages/setup/Labels";
import BuyNowProperties from "@/pages/buynow/properties";
import Keywords from "@/pages/setup/Keywords";
import BuyNowComponents from "@/pages/buynow/components";
import Availability from "@/pages/availability/main";
import Products from "@/pages/setup/Products";
import { Markets } from "@/pages/setup/Markets";
import Categories from "@/pages/setup/Categories";
import ProductListings from "@/pages/availability/productListings";
import PricingOverview from "@/pages/pricing/overview";
import PackshotsAndVideos from "@/pages/content/PackshotsAndVideos";
import Default from "@/pages/default";
import ContractsOverview from "@/pages/contracts";
import RightsGuard from "@/components/guards/RightsGuard";
import Page403 from "@mira/pages/auth/Page403";
import { UsersTraffic } from "@/pages/accounts/UsersTraffic";

export enum Routes {
  home = "/",
  signIn = "/auth-cover/sign-in",
  startPage = "/",
  noPermisssion = "/403",
}

const routes = [
  {
    path: Routes.home,
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/management/",
        element: <RightsGuard requiredRight={'admin'}/>,
        children: [
          {
            path: "contracts",
            element: <ContractsOverview />,
          },
          {
            path: "usage",
            element: <UsersTraffic />,
          },
        ]
      },
      {
        path: "/",
        element: <Default />,
      },
      {
        path: "/pricing/overview",
        element: <PricingOverview />,
        requiredModules: [15],
      },
      {
        path: "/buy-now/dashboard",
        element: <Analytics />,
        requiredModules: [16],
      },
      {
        path: "/buy-now/transactions",
        element: <BuyNowTransactions />,
        requiredModules: [17],
      },
      {
        path: "/buy-now/properties",
        element: <BuyNowProperties />,
        requiredModules: [24],
      },
      {
        path: "/buy-now/components",
        element: <BuyNowComponents />,
        requiredModules: [23],
      },
      {
        path: "/visibility/share-of-search",
        element: <VisibilityShareOfSearch />,
        requiredModules: [7],
      },
      {
        path: "/content/merchants-product-catalog",
        element: <Content />,
        requiredModules: [9],
      },
      {
        path: "/content/ai-optimizer",
        element: <AiOptimizer />,
        requiredModules: [12],
      },
      {
        path: "/content/ratings-and-reviews",
        element: <RatingsAndReviews />,
        requiredModules: [10],
      },
      {
        path: "/content/packshots-and-images",
        element: <PackshotsAndVideos />,
        requiredModules: [11],
      },
      {
        path: "/setup/labels",
        element: <Labels />,
        requiredModules: [18],
      },
      {
        path: "/setup/keywords",
        element: <Keywords />,
        requiredModules: [19],
      },
      {
        path: "/setup/categories",
        element: <Categories />,
        requiredModules: [20],
      },
      {
        path: "/setup/products",
        element: <Products />,
        requiredModules: [21],
      },
      {
        path: "/setup/markets",
        element: <Markets />,
        requiredModules: [22],
      },
      {
        path: "/availability/overview",
        element: <Availability />,
        requiredModules: [14],
      },
      {
        path: "/availability/product-listings",
        element: <ProductListings />,
        requiredModules: [8],
      },
    ],
  },

  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
    ],
  },
  {
    path: "auth-cover",
    element: <AuthCoverLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
    ],
  },
  {
    path: "/",
    element: <ErrorLayout />,
    children: [
      {
        path: "403",
        element: <Page403 />
      }
    ]
  },
  {
    path: "*",
    element: <ErrorLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;

// Get the required modules for a given path
export const getRequiredModules = (path: string): number[] => {
  const route = (
    routes[0].children as Array<{
      path: string;
      element: React.ReactElement;
      requiredModules?: number[];
    }>
  ).find((route) => route.path === path);
  return route?.requiredModules || [];
};

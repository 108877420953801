import { createMutationFunction } from "../mutations/createMutationFunction";
import { createQueryFunction } from "../queries/createQueryFunction";
import { useApiMutation } from "../useApiMutation";
import { useApiQuery } from "../useApiQuery";
import { PropertiesApiType, PropertiesResponse, PropertiesParams, ComponentsParams, ComponentsResponse, CreatePropertyApi, CreatePropertyParams } from "./types";

const basePath = "app/buy-now/"

export const useProperties = (): PropertiesApiType => {
    const allPropertiesApi = useApiQuery<PropertiesResponse[], PropertiesParams>({
      key: "installations",
      queryFn: createQueryFunction(`${basePath}installations`),
    });
    return { allPropertiesApi };
};

export const useCreateProperties = () => {
  return useApiMutation<PropertiesResponse, CreatePropertyParams>({
      mutationFn: (variables) =>
          createMutationFunction<PropertiesResponse, CreatePropertyParams>(
              `${basePath}installations`
          )(variables),
        });
};
import { Autocomplete, Dialog, DialogTitle, DialogContent, DialogActions,  Grid, Button, TextField, Alert, FormControl } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { localeDateFormat, localeMapping } from '../../components/TimeRangePicker/constants';
import { useTranslation } from 'react-i18next';
import useData from "@/hooks/auth/useData";
import { useState, useMemo } from 'react';
import { AccountResponse, CreateContractRequest, ModulesResponse } from "@/api/management/types";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { UserResponse } from "@/types/user";
import { 
  createBillingCycleOptions, 
  createNoticePeriodOptions, 
  createMinTermOptions 
} from './types';

interface CreateContractModalProps {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  listApi: any;
  accounts: AccountResponse[];
  modules: ModulesResponse[];
  manufacturers: any[];
  accountExecutives: UserResponse[];
}

interface ModuleEntry {
  moduleId: string;
  manufacturerId: string;
  quantity: string;
}

const CreateContractModal = ({ 
  open, 
  onClose, 
  onSuccess, 
  listApi,
  accounts,
  modules,
  manufacturers,
  accountExecutives
}: CreateContractModalProps) => {
  const { t } = useTranslation();
  const { user, createContractApi } = useData();
  const [contractId, setContractId] = useState('');
  const [company, setCompany] = useState('');
  const [minTerm, setMinTerm] = useState('');
  const [noticePeriod, setNoticePeriod] = useState('');
  const [billingCycle, setBillingCycle] = useState('');
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [comment, setComment] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [rows, setRows] = useState<AccountResponse[]>([]);
  const [moduleEntries, setModuleEntries] = useState<ModuleEntry[]>([{ 
    moduleId: '', 
    manufacturerId: '', 
    quantity: '' 
  }]);
  const [closingDate, setClosingDate] = useState<Date | null>(null);
  const [firstAccessDate, setFirstAccessDate] = useState<Date | null>(null);
  const [automaticRenewal, setAutomaticRenewal] = useState(true);
  const [paymentDue, setPaymentDue] = useState('');
  const [selectedAccountExecutive, setSelectedAccountExecutive] = useState<UserResponse | null>(null);
  const [touched, setTouched] = useState<{ [key: string]: boolean }>({});
  const [wasSubmitted, setWasSubmitted] = useState(false);

  const billingCycleOptions = useMemo(() => createBillingCycleOptions(t), [t]);
  const noticePeriodOptions = useMemo(() => createNoticePeriodOptions(t), [t]);
  const minTermOptions = useMemo(() => createMinTermOptions(t), [t]);

  const numberFormat = user?.settings?.numberFormat;
  const userLocale = numberFormat && numberFormat in localeMapping
    ? localeMapping[numberFormat as keyof typeof localeMapping]
    : 'en';
  const userDateFormat = numberFormat && numberFormat in localeDateFormat
    ? localeDateFormat[numberFormat as keyof typeof localeDateFormat]
    : 'YYYY/MM/DD';
   

  const handleClose = () => {
    setContractId('');
    setCompany('');
    setMinTerm('');
    setNoticePeriod('');
    setBillingCycle('');
    setStartDate(null);
    setComment('');
    setError(null);
    setTouched({});
    setWasSubmitted(false);
    setModuleEntries([{ moduleId: '', manufacturerId: '', quantity: '' }]);
    setClosingDate(null);
    setFirstAccessDate(null);
    setAutomaticRenewal(true);
    setPaymentDue('');
    setSelectedAccountExecutive(null);
    onClose();
  };

  /**
   * Validates if a contract ID contains a valid date in its first 6 digits
   * 
   * The first 6 digits of the contract ID represent a date in the format YYMMDD where:
   * - YY is the last 2 digits of the year (20XX)
   * - MM is the month (01-12)
   * - DD is the day (01-31)
   * 
   * @param contractId - The contract ID string to validate
   * @returns boolean - True if the first 6 digits form a valid date, false otherwise
   */
  const isValidContractDate = (contractId: string): boolean => {
    if (contractId.length < 6) return false;
    
    const dateStr = contractId.substring(0, 6);
    const year = parseInt(dateStr.substring(0, 2)) + 2000;
    const month = parseInt(dateStr.substring(2, 4)) - 1;
    const day = parseInt(dateStr.substring(4, 6));
    
    const date = new Date(year, month, day);
    
    return date instanceof Date && 
           !isNaN(date.getTime()) && 
           date.getFullYear() - 2000 === parseInt(dateStr.substring(0, 2)) &&
           date.getMonth() + 1 === parseInt(dateStr.substring(2, 4)) &&
           date.getDate() === parseInt(dateStr.substring(4, 6));
  };

  const isFieldEmpty = (value: any): boolean => {
    return value === null || value === undefined || value === '';
  };

  const getFieldError = (fieldName: string, value: any): boolean => {
    return (touched[fieldName] || wasSubmitted) && isFieldEmpty(value);
  };

  const handleSubmit = async () => {
    try {
      setError(null);
      setWasSubmitted(true);

      // Mark all fields as touched on submit
      const allFields = {
        contractId: true,
        company: true,
        closingDate: true,
        firstAccessDate: true,
        startDate: true,
        minTerm: true,
        noticePeriod: true,
        billingCycle: true,
        paymentDue: true,
      };
      setTouched(allFields);

      // Validate contract ID format first
      if (isNaN(Number(contractId)) || !isValidContractDate(contractId)) {
        setError(t('pages.contracts.create.alerts.invalidContractId'));
        return;
      }

      // Check all required fields without considering touched state
      if (
        isFieldEmpty(contractId) ||
        isFieldEmpty(company) ||
        isFieldEmpty(closingDate) ||
        isFieldEmpty(firstAccessDate) ||
        isFieldEmpty(startDate) ||
        isFieldEmpty(minTerm) ||
        isFieldEmpty(noticePeriod) ||
        isFieldEmpty(billingCycle) ||
        isFieldEmpty(paymentDue) ||
        moduleEntries.length === 0 ||
        moduleEntries.some(entry => 
          isFieldEmpty(entry.moduleId) || 
          isFieldEmpty(entry.manufacturerId) || 
          isFieldEmpty(entry.quantity)
        )
      ) {
        setError(t('pages.contracts.create.alerts.requiredFields'));
        return;
      }

      const startDateUnix = startDate ? startDate.valueOf() : null;
      const request: CreateContractRequest = {
        ac_id: Number(contractId),
        account_id: Number(company),
        start: startDateUnix,
        end: null,
        term: Number(minTerm),
        notice: Number(noticePeriod),
        cycle: Number(billingCycle),
        status: 1,
        closing_date: closingDate ? closingDate.valueOf() : null,
        access_date: firstAccessDate ? firstAccessDate.valueOf() : null,
        automatic_renewal: automaticRenewal ? 1 : 0,
        payment_due: Number(paymentDue),
        executive: selectedAccountExecutive?.user_id ?? null,
        comment: comment || null,
        modules: moduleEntries
          .filter(entry => !(Number(entry.moduleId) === 0 && Number(entry.manufacturerId) === 0 && Number(entry.quantity) === 0))
          .map((entry) => ({
            am_id: Number(entry.moduleId),
            manufacturer: Number(entry.manufacturerId), 
            quantity: Number(entry.quantity)
          }))
      }
      // Add your contract creation API call here
      await createContractApi.execute(request);

      // The tree response is more complicated to simple add it as it needs a tree form.
      listApi.execute(undefined, false);
      onSuccess();
      handleClose();
    } catch (error) {
      setError(t('pages.contracts.create.alerts.createFailed')); 
    }
  };

  const handleAddModule = () => {
    setModuleEntries([...moduleEntries, { 
      moduleId: '', 
      manufacturerId: '', 
      quantity: '' 
    }]);
  };

  const handleRemoveModule = (index: number) => {
    setModuleEntries(moduleEntries.filter((_, i) => i !== index));
  };

  const handleModuleChange = (index: number, field: keyof ModuleEntry, value: string) => {
    const newEntries = [...moduleEntries];
    newEntries[index] = { ...newEntries[index], [field]: value };
    setModuleEntries(newEntries);
  };

  const onCloseHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    handleClose();
  };

  return (
    <Dialog 
      open={open} 
      maxWidth="sm" 
      fullWidth 
      sx={{ '& .MuiDialog-paper': { maxWidth: '630px' } }}
      disableEscapeKeyDown={true}
      onClose={(event: object, reason: string) => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
    >
      <DialogTitle>{t('pages.contracts.create.title')}</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          {/* First Row */}
          <Grid item xs={12} md={2.4}>
            <TextField
              margin="dense"
              size="small"
              label={t('tableColumns.contractId')}
              fullWidth
              required
              variant="standard"
              type="text"
              placeholder="ID"
              InputLabelProps={{shrink: true}}
              error={getFieldError('contractId', contractId)}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              value={contractId}
              onChange={(e) => setContractId(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={9.6}>
            <Autocomplete
              size="small"
              options={accounts}
              openOnFocus={true}
              getOptionLabel={(option) => option.name || option.abbreviation}
              value={accounts.find(acc => String(acc.account_id) === company) || null}
              onChange={(_, newValue) => setCompany(String(newValue?.account_id) || '')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="dense"
                  label={t('tableColumns.account')}
                  required
                  error={getFieldError('company', company)}
                  InputLabelProps={{shrink: true}}
                  variant="standard"
                  placeholder={t('pages.contracts.create.accountPlaceholder') || 'Company name'}
                />
              )}
            />
          </Grid>

          {/* Second Row */}
            <Grid item xs={12} md={2.4}>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={userLocale}>
                <DatePicker
                  label={t('tableColumns.closingDate')}
                  value={closingDate}
                  onChange={(newValue) => setClosingDate(newValue)}
                  format={userDateFormat}
                  slotProps={{
                    textField: {
                      size: "small",
                      margin: "dense",
                      fullWidth: true,
                      required: true,
                      variant: "standard",
                      InputLabelProps: {shrink: true},
                      error: getFieldError('closingDate', closingDate),
                      onBlur: () => setTouched(prev => ({ ...prev, closingDate: true }))
                    }
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={2.4}>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={userLocale}>
                <DatePicker
                  label={t('tableColumns.firstAccessDate')}
                  value={firstAccessDate}
                  onChange={(newValue) => setFirstAccessDate(newValue)}
                  format={userDateFormat}
                  slotProps={{
                    textField: {
                      size: "small",
                      margin: "dense",
                      fullWidth: true,
                      required: true,
                      variant: "standard",
                      InputLabelProps: {shrink: true},
                      error: getFieldError('firstAccessDate', firstAccessDate),
                      onBlur: () => setTouched(prev => ({ ...prev, firstAccessDate: true }))
                    }
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={2.4}>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={userLocale}>
                <DatePicker
                  label={t('tableColumns.startDate')}
                  value={startDate}
                  onChange={(newValue) => setStartDate(newValue)}
                  format={userDateFormat}
                  slotProps={{
                    textField: {
                      size: "small",
                      margin: "dense",
                      fullWidth: true,
                      required: true,
                      variant: "standard",
                      InputLabelProps: {shrink: true},
                      error: getFieldError('startDate', startDate),
                      onBlur: () => setTouched(prev => ({ ...prev, startDate: true }))
                    }
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={4.8}>
              <Autocomplete
                size="small"
                options={accountExecutives.map(ae => ({
                  value: ae.user_id?.toString(),
                  key: ae.user_id?.toString(),
                  label: ae.first_name + " " + ae.last_name
                }))}
                value={selectedAccountExecutive ? {
                  value: selectedAccountExecutive.user_id?.toString(),
                  key: selectedAccountExecutive.user_id?.toString(),
                  label: selectedAccountExecutive.first_name + " " + selectedAccountExecutive.last_name
                } : null}
                onChange={(_, newValue) => setSelectedAccountExecutive(
                  accountExecutives.find(ae => ae.user_id?.toString() === newValue?.value) || null
                )}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="dense"
                    required
                    label={t('tableColumns.accountExecutive')}
                    variant="standard"
                    error={getFieldError('accountExecutive', selectedAccountExecutive)}
                    InputLabelProps={{shrink: true}}
                    placeholder={t('pages.contracts.create.whoClosed') || 'Who has closed the deal?'}
                  />
                )}
              />
            </Grid>

          {/* Third Row */}

            <Grid item xs={12} md={2.4}>
              <Autocomplete
                size="small"
                options={[
                  { value: 'true', label: t('tableColumns.yes') },
                  { value: 'false', label: t('tableColumns.no') }
                ]}
                value={automaticRenewal ? 
                  { value: 'true', label: t('tableColumns.yes') } :
                  { value: 'false', label: t('tableColumns.no') }
                }
                onChange={(_, newValue) => setAutomaticRenewal(newValue?.value === 'true')}
                getOptionLabel={(option) => option.label}
                disableClearable
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="dense"
                    required
                    label={t('tableColumns.automaticRenewal')}
                    variant="standard"
                    InputLabelProps={{shrink: true}}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={2.4}>
              <Autocomplete
                size="small"
                options={minTermOptions}
                value={minTerm ? minTermOptions.find(opt => opt.value.toString() === minTerm) : null}
                onChange={(_, newValue) => setMinTerm(newValue ? newValue.value.toString() : '')}
                getOptionLabel={(option) => option.label}
                disableClearable
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="dense"
                    label={t('tableColumns.term')}
                    variant="standard"
                    required
                    error={getFieldError('minTerm', minTerm)}
                    InputLabelProps={{shrink: true}}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={2.4}>
              <Autocomplete
                size="small"
                options={noticePeriodOptions}
                value={noticePeriod ? noticePeriodOptions.find(opt => opt.value.toString() === noticePeriod) : null}
                onChange={(_, newValue) => setNoticePeriod(newValue ? newValue.value.toString() : '')}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="dense"
                    error={getFieldError('noticePeriod', noticePeriod)}
                    label={t('tableColumns.noticePeriod')}
                    variant="standard"
                    required
                    InputLabelProps={{shrink: true}}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={2.4}>
              <Autocomplete
                size="small"
                options={billingCycleOptions}
                value={billingCycle ? billingCycleOptions.find(opt => opt.value.toString() === billingCycle) : null}
                onChange={(_, newValue) => setBillingCycle(newValue ? newValue.value.toString() : '')}
                getOptionLabel={(option) => option.label}
                disableClearable
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="dense"
                    error={getFieldError('billingCycle', billingCycle)}
                    label={t('tableColumns.billingCycle')}
                    variant="standard"
                    required
                    InputLabelProps={{shrink: true}}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={2.4}>
              <TextField
                variant="standard"
                margin="dense"
                size="small"
                label={t('tableColumns.paymentDue')}
                type="number"
                fullWidth
                required
                InputLabelProps={{
                  shrink: true
                }}
                placeholder={t('pages.contracts.create.paymentDuePlaceholder') || 'In days'}
                value={paymentDue}
                onChange={(e) => setPaymentDue(e.target.value)}
                error={getFieldError('paymentDue', paymentDue)}
                onBlur={() => setTouched(prev => ({ ...prev, paymentDue: true }))}
              />
            </Grid>

          {/* Module Entries */}
          {moduleEntries.map((entry, index) => (
            <Grid container item spacing={2} key={index}>
              <Grid item xs={12} md={4.8}>
                <FormControl fullWidth margin="dense" size="small">
                  <Autocomplete
                    size="small"
                    options={manufacturers.map(manufacturer => ({
                      value: manufacturer.ID.toString(),
                      label: manufacturer.NAME
                    }))}
                    value={manufacturers.find(m => String(m.ID) === entry.manufacturerId) ? {
                      value: entry.manufacturerId.toString(),
                      label: manufacturers.find(m => String(m.ID) === entry.manufacturerId)?.NAME || ""
                    } : null}
                    onChange={(_, newValue) => handleModuleChange(
                      index,
                      'manufacturerId', 
                      newValue?.value || ''
                    )}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t('filters.manufacturer')}
                        variant="standard"
                        required
                        placeholder={t('pages.contracts.create.makerPlaceholder') || 'Assign maker'}
                        error={getFieldError(`manufacturerId_${index}`, entry.manufacturerId)}
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4.8}>
                <FormControl fullWidth margin="dense" size="small">
                  <Autocomplete
                    size="small"
                    options={modules.map(module => ({
                      value: module.am_id.toString(),
                      label: module.module
                    }))}
                    value={modules.find(m => String(m.am_id) === entry.moduleId) ? {
                      value: entry.moduleId.toString(),
                      label: modules.find(m => String(m.am_id) === entry.moduleId)?.module || ""
                    } : null}
                    onChange={(_, newValue) => handleModuleChange(
                      index,
                      'moduleId',
                      newValue?.value || ''
                    )}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t('tableColumns.mavenProduct')}
                        variant="standard"
                        required
                        placeholder={t('pages.contracts.create.productPlaceholder') || 'Specify product'}
                        error={getFieldError(`moduleId_${index}`, entry.moduleId)}
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={1.4}>
                <TextField
                  fullWidth
                  size="small"
                  margin="dense"
                  label={t('tableColumns.quantity')}
                  type="number"
                  value={entry.quantity}
                  onChange={(e) => handleModuleChange(index, 'quantity', e.target.value)}
                  required
                  error={getFieldError(`quantity_${index}`, entry.quantity)}
                  variant="standard"
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              <Grid item xs={12} md={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {index === moduleEntries.length - 1 ? (
                  <IconButton onClick={handleAddModule} color="primary">
                    <AddIcon />
                  </IconButton>
                ) : (
                  <IconButton onClick={() => handleRemoveModule(index)} color="error">
                    <DeleteIcon />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          ))}

          {/* Fourth Row */}
          <Grid item xs={12}>
            <TextField
              variant="standard"
              size="small"
              margin="dense"
              label={t('tableColumns.comment')}
              fullWidth
              multiline
              rows={4}
              value={comment}
              InputLabelProps={{
                shrink: true
              }}
              placeholder={t('pages.contracts.create.commentPlaceholder') || 'You can add some comments here'}
              onChange={(e) => setComment(e.target.value)}
            />
          </Grid>


        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'flex-start', paddingLeft: 6, paddingRight: 6 }}>
        <Button onClick={handleClose} size="large">{t('general.cancel')}</Button>
        <Button onClick={handleSubmit} variant="contained" size="large">
          {t('general.create')}
        </Button>
        {error && (
          <Alert variant="filled" severity="error" sx={{ width: '100%', paddingTop: 1, paddingBottom: 1 }}>
            {error}
          </Alert>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CreateContractModal;

import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import {
    Typography as MuiTypography, Button, Paper,
} from "@mui/material";
import { spacing } from "@mui/system";
import SupportModal from "@/components/support-modal/SupportModal";
import BlurOverlay from "@/components/BlurOverlay";

const Typography = styled(MuiTypography)(spacing);

const PaperWrapper = styled('div')({
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 101, // Ensure Paper is on top of the blur overlay
});

function Upgrade() {
    const { t } = useTranslation();
    const [openSupportModal, setOpenSupportModal] = React.useState(false);

    useEffect(() => {
        document.body.style.overflow = 'hidden'; // Disable scrolling

        // Cleanup on unmount
        return () => {
            document.body.style.overflow = '';
        };
    }, []);

    return (
        <React.Fragment>
            <BlurOverlay isOpen={true} />
            <PaperWrapper tabIndex={0}>
                <Paper elevation={3} sx={{p: 6, maxWidth: 480, margin: "auto"}}>
                    <Typography variant="h3" gutterBottom>
                        {t("pages.upgrade.title")}
                    </Typography>
                    <Typography variant="subtitle1">
                        {t("pages.upgrade.description")}

                        <br/>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setOpenSupportModal(true)}
                            style={{marginTop: 8}}
                        >
                            {t("pages.upgrade.button")}
                        </Button>
                    </Typography>
                </Paper>
            </PaperWrapper>
            <SupportModal
                open={openSupportModal}
                onClose={() => setOpenSupportModal(false)}
            />
        </React.Fragment>
    );
}

export default Upgrade;

import {
  Box,
  Select,
  SelectChangeEvent,
  MenuItem,
  Typography,
  InputLabel,
  FormControl,
} from "@mui/material";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import { useState } from "react";
import { t } from "i18next";

export const CollaborationSelect = ({ disabled }: { disabled?: boolean }) => {
  const [selected, setSelected] = useState<string>("private");

  const handleChange = (event: SelectChangeEvent) => {
    setSelected(event.target.value as string);
  };

  const renderCollaborationOption = (value: string) => (
    <Box
      display="flex"
      justifyContent="left"
      alignItems="center"
      sx={{ py: 0.4, marginTop: "0px", marginLeft: "5px" }}
    >
      <LockOpenOutlinedIcon
        sx={{ color: "green", marginRight: "7px", fontSize: "14px" }}
      />
      <Typography sx={{ fontWeight: 400, fontSize: "14px" }}>
        {value}
      </Typography>
    </Box>
  );

  return (
    <FormControl disabled={disabled} margin="dense" fullWidth size="small">
      <InputLabel margin="dense" size="small" focused={true}>
        {t("tableColumns.collaboration")}
      </InputLabel>
      <Select
        labelId="collaboration-select-label"
        label={t("tableColumns.collaboration")}
        id="collaboration-select"
        value={selected}
        margin="dense"
        size="small"
        onChange={handleChange}
        renderValue={() => renderCollaborationOption(t("general.private"))}
        disabled={disabled}
        sx={{
          height: "48",
          ".MuiSelect-select": {
            padding: "6px 6px",
          },
        }}
      >
        <MenuItem value="private" sx={{ padding: "0px 0px" }}>
          {renderCollaborationOption(t("general.private"))}
        </MenuItem>
      </Select>
    </FormControl>
  );
};

import React from 'react';
import styled from '@emotion/styled';
import { BlurOverlayProps } from './types';

const StyledBlurOverlay = styled('div')<BlurOverlayProps>(({ isOpen }) => ({
	position: 'fixed',
	top: 0,
	left: 0,
	right: 0,
	bottom: 0,
	background: 'rgba(0, 0, 0, 0.5)',
	backdropFilter: isOpen ? 'blur(4px)' : 'none',
	zIndex: isOpen ? 100 : -1,
	pointerEvents: isOpen ? 'all' : 'none',
}));

const BlurOverlay: React.FC<BlurOverlayProps> = ({ isOpen, tabIndex = -1 }) => {
	return <StyledBlurOverlay isOpen={isOpen} tabIndex={tabIndex}  />;
};

export default BlurOverlay;

import {
  ContentType,
  createMutationFunction,
} from "../mutations/createMutationFunction";
import { createQueryFunction } from "../queries/createQueryFunction";
import { useApiMutation } from "../useApiMutation";
import { useApiQuery } from "../useApiQuery";
import {
  LabelUpdatePayload,
  LabelUpdateRequest,
  LabelsResponse,
  SetupParams,
  LabelCreateRequest,
  LabelDeleteRequest,
  LabelsDeleteResponse,
} from "./types";

const key = "labels";

export const useLabels = () => {
  return useApiQuery<LabelsResponse[], SetupParams>({
    key: key,
    queryFn: createQueryFunction<LabelsResponse[], SetupParams>(
      "app/setup/labels"
    ),
  });
};

export const updateLabel = () => {
  const labels = useApiMutation<LabelsResponse, LabelUpdateRequest>({
    mutationFn: (variables, signal) => {
      const { id, keywords = [], categories = [], ...body } = variables!;
      return createMutationFunction<LabelsResponse, LabelUpdatePayload>(
        `app/setup/labels/${id}`,
        ContentType.JSON,
        "PUT"
      )({ ...body, keywords, categories}, signal);
    },
  });

  return labels;
};

export const createLabel = () => {
  const labels = useApiMutation<LabelsResponse, LabelCreateRequest>({
    mutationFn: (variables, signal) => {
      return createMutationFunction<LabelsResponse, LabelCreateRequest>(
        "app/setup/labels",
        ContentType.JSON,
        "POST"
      )(variables, signal);
    },
  });

  return labels;
};

export const deleteLabel = () => {
  const result = useApiMutation<LabelsDeleteResponse, LabelDeleteRequest>({
    mutationFn: (variables, signal) => {
      const { ids } = variables!;
      return createMutationFunction<LabelsDeleteResponse, (string|number)[]>(
        `app/setup/labels`,
        ContentType.JSON,
        "DELETE"
      )(ids, signal);
    },
  });

  return result;
};
import markdownit from 'markdown-it';
import DOMPurify from 'dompurify';

export const sanitize = (text: string | Array<string>) => {
    const mdi = markdownit({ quotes: '„“‚‘'});
    let contentHTML = "";
    if(typeof text === 'string') {    
        //check for html tags
        if(/<\/?[a-z][\s\S]*>/i.test(text)) {
            contentHTML = text;
        } else {
            contentHTML = mdi.render(text);
        }
    } else if(Array.isArray(text)) {
        // creates string from array and adds new line in order to have correct structure
        // assumption: no new lines used (can handle both). 
        // empty string signals line break (each line on entry).
        const mdFromArray = text.reduce((acc, cur) => {
            let val = acc + cur;
            if(cur === '' || !cur.endsWith('\n')) {
                val += '\n';
            }
            return val;
        }, '');
        contentHTML = mdi.render(mdFromArray);
    }
    // santize to avoid XSS
    return DOMPurify.sanitize(contentHTML);
}
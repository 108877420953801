import { useApiQuery } from "../useApiQuery";
import { createQueryFunction } from "../queries/createQueryFunction";
import { UserResponse } from "@/types/user";
import { AdminsApiType } from "./types";

export const useAdmin = (): AdminsApiType => {
    return useApiQuery<UserResponse[], {}>({
      key: 'admins',
      queryFn: createQueryFunction(`app/management/admins`),
    });
};
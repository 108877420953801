import { AccountsApiType } from "./types";
import { useUsersTraffic } from "./useUsersTraffic";


const useAccounts = (): AccountsApiType => {
  const allUsersTrafficApi = useUsersTraffic();

  return {
    allUsersTrafficApi,
  }
};

export default useAccounts;
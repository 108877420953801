import { useCreateProperties, useProperties } from "./useProperties";
import { BuynowApiType } from "./types";
import {
    useAssignConfigComponent,
    useComponents,
    useCreateComponent,
    useUpdateAssignmentConfigComponent,
    useUpdateComponent
} from "./useComponents";
import {useCreateConfig} from "@/api/buynow/useConfigs";
import { useDefContentJson } from "./useDefContentJson";
import { useDefSocialJson } from "./useDefSocialJson";

const useBuynow = (): BuynowApiType => {
    const allPropertiesApi = useProperties();
    const componentsApi = useComponents();
    const updateComponentsApi = useUpdateComponent();
    const assignConfigComponentApi= useAssignConfigComponent();
    const updateAssignmentConfigComponentApi = useUpdateAssignmentConfigComponent();
    const createConfigApi = useCreateConfig();
    const defSocialJsonApi = useDefSocialJson();
    const defContentJsonApi = useDefContentJson();
    const createComponentApi = useCreateComponent();
    const createPropertyApi = useCreateProperties();

    return {
        ...allPropertiesApi,
        componentsApi,
        updateComponentsApi,
        assignConfigComponentApi,
        updateAssignmentConfigComponentApi,
        createConfigApi,
        defSocialJsonApi,
        defContentJsonApi,
        createComponentApi,
        createPropertyApi
    }
};

export default useBuynow;
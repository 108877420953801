import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Typography,
  Alert,
} from '@mui/material';
import { AxiosError } from 'axios';

interface DynamicDialogProps {
  open: boolean;
  onClose: () => void;
  onClick: () => void;
  successText?: string;
  errorText?: string;
  isLoading?: boolean;
  closeOnBackdropClick?: boolean;
  content: React.ReactNode;
  title: string;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  isPrimaryBtnDisabled?: boolean;
  showSuccessOutside?: boolean;
  onSuccessOutside?: (message: string) => void; // Callback for external success handling
  footer?: React.ReactNode;
}

interface AxiosErrorDetail {
  detail: string;
}

const DynamicDialog: React.FC<DynamicDialogProps> = ({
  open,
  onClose,
  onClick,
  successText = "Operation successful",
  errorText = "Something went wrong",
  isLoading = false,
  closeOnBackdropClick = true,
  content,
  title,
  isPrimaryBtnDisabled,
  primaryButtonText,
  secondaryButtonText,
  showSuccessOutside = false,
  footer,
}) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const handleAxiosError = (error: AxiosError<AxiosErrorDetail>, errorText: string): string => {
    return error?.response?.data?.detail || errorText;
  };

  const handleClick = async () => {
    setErrorMessage(null);
    setSuccessMessage(null);
    try {
      await onClick();
      if (showSuccessOutside) {
        onClose();
      } else {
        setSuccessMessage(successText);
      }
    } catch (error: unknown) {
      const errorMessage = handleAxiosError(error as AxiosError<AxiosErrorDetail>, errorText);
      setErrorMessage(errorMessage);
    }
  };

  const onDialogClose = () => {
    setErrorMessage(null);
    setSuccessMessage(null);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={closeOnBackdropClick ? onDialogClose : undefined}
      aria-labelledby="dynamic-dialog-title"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle fontSize={"24px"}>{title}</DialogTitle>
      <DialogContent>
        {content}
        {errorMessage && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {errorMessage}
          </Alert>
        )}
        {!showSuccessOutside && successMessage && (
          <Alert severity="success" sx={{ mt: 2 }}>
            {successMessage}
          </Alert>
        )}
      </DialogContent>
      <DialogActions sx={{ padding: "0 24px 0px 24px" , paddingBottom: footer ? 0 : "24px"}}>
        {secondaryButtonText && (
          <Button onClick={onDialogClose} color="secondary" disabled={isLoading}>
            {secondaryButtonText}
          </Button>
        )}
        {primaryButtonText && (
          <Button onClick={handleClick} variant="contained" disabled={isPrimaryBtnDisabled || isLoading}>
            {isLoading ? <CircularProgress size={24} /> : primaryButtonText}
          </Button>
        )}
      </DialogActions>
      { footer && <DialogContent>{footer}</DialogContent>}
    </Dialog>
  );
};

export default DynamicDialog;

import { t } from "i18next";
import { createMutationFunction } from "./mutations/createMutationFunction";
import { ApiMutation, ErrorCallback, useApiMutation } from "./useApiMutation";

export type UpdateUserApiType = ApiMutation<
  UpdateUserResponse,
  UpdateUserParams
>;

/**
 * Interface for user update parameters
 */
export interface UpdateUserParams {
  first_name?: string;
  last_name?: string;
  gender?: string;
  settings?: {
    uiMode?: string;
    language?: string;
    currency?: string;
    numberFormat?: string;
    drawerIsOpen?: boolean;
    drawerWidth?: number;
  };
}

/**
 * Interface for user update response
 */
export interface UpdateUserResponse {
  success: boolean;
  message?: string;
}

/**
 * Custom error handling for user update
 * @param {ErrorCallback<UpdateUserResponse>} params - The error callback parameters
 */
const handleCustomError = ({
  error,
  setError,
  setData,
}: ErrorCallback<UpdateUserResponse>) => {
  const errorMessage =
    error.response && error.response.status === 400
      ? t("user.updateError")
      : `${t("errors.globalError")}: ${error.message}`;

  setError(errorMessage);
  setData(undefined);
};

/**
 * Custom hook to handle the user update mutation using react-query
 * @returns {ApiMutation<UpdateUserResponse, UpdateUserParams>} - The mutation state and execute function
 */
export const useUpdateUser = () => {
  return useApiMutation<UpdateUserResponse, UpdateUserParams>({
    mutationFn: createMutationFunction<UpdateUserResponse, UpdateUserParams>(
      "update-user"
    ),
    onErrorCallback: handleCustomError,
  });
};

import { useEffect, useMemo, useState } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import useData from "@/hooks/auth/useData";
import DynamicDialog from "@/components/DynamicDialog";
import { ComponentsResponse } from "@/api/buynow/types";
import useCustomTableUtils from "@/hooks/useColumnsData/utils";

export default function CreateComponent({
  dialogOpen,
  setDialogOpen,
  onSuccess,
}: {
  dialogOpen: boolean;
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSuccess: (newComponent: ComponentsResponse) => void;
}) {
  const { na } = useCustomTableUtils();
  const { t } = useTranslation();
  const { selectedManufacturers, allPropertiesApi, productsApi, createConfigApi, createComponentApi } = useData();
  const [isLoading, setIsLoading] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const [urlErrors, setUrlErrors] = useState({ imprint: false, dataProtection: false });
  const [selectedProduct, setSelectedProduct] = useState<any>(null);
  const [selectedProperty, setSelectedProperty] = useState<any>(null);
  const [filteredProperties, setFilteredProperties] = useState<any[]>([]);
  const theme = useTheme();

  const manufacturerIds = useMemo(() => selectedManufacturers.map((m) => m.ID), [selectedManufacturers]);

  useEffect(() => {
    if (!productsApi.data && manufacturerIds?.length > 0) {
      productsApi.execute({ m: manufacturerIds });
    }
  }, [manufacturerIds]);

  const productsApiData = useMemo(() => productsApi?.data || [], [productsApi?.data]);

  const availableProperties = useMemo(() => allPropertiesApi?.data || [], [allPropertiesApi?.data]);

  const initialFormState = {
    type: 0,
    install: "",
    product: "",
    demo: false,
    imprint: "",
    dataProtection: "",
  };

  const [formState, setFormState] = useState(initialFormState);

  const componentsTypes = [
    { value: 0, label: t("pages.buyNowComponent.typeContent") },
    { value: 1, label: t("pages.buyNowComponent.typePerformance") },
  ];

  const isValidUrl = (url: string) => {
    const urlRegex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([\/\w .-]*)*\/?$/;
    return urlRegex.test(url);
  };

  const validateForm = () => {
    const { install, product, imprint, dataProtection } = formState;
    return install && product && imprint && dataProtection && isValidUrl(imprint) && isValidUrl(dataProtection);
  };

  useEffect(() => {
    setIsBtnDisabled(!validateForm());
    setUrlErrors({
      imprint: formState.imprint !== "" && !isValidUrl(formState.imprint),
      dataProtection: formState.dataProtection !== "" && !isValidUrl(formState.dataProtection),
    });
  }, [formState]);

  const handleDialogClick = async () => {
    setIsLoading(true);
    try {
      const resolvedComment = t("pages.buyNowComponent.firstConfigurationComment") ?? "";
      const createdConfig = await createConfigApi?.execute({
        manufacturer_id: selectedProduct?.MANUFACTURER_ID,
        config: {
          footer: {
            imprint: formState.imprint,
            dataProtection: formState.dataProtection,
          },
        },
        comment: `${resolvedComment} ${selectedProduct.PZN}`,
        status: 1,
      });
      console.log("The Config was created successfully with an ID of: ", createdConfig.CID);
      const createdComponent = await createComponentApi?.execute({
        demo: formState.demo,
        install: selectedProperty?.id,
        product: selectedProduct.PID,
        configs: [
          {
            variants: [selectedProduct.PZN],
            config_id: createdConfig.CID,
          },
        ],
      });
  
      console.log("The Component was created successfully: ", createdComponent);
  
      onSuccess(createdComponent as ComponentsResponse);
    } catch (error) {
      console.error(t("pages.buyNowComponent.operationFailed"), error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const filtered = availableProperties.filter((prop) => prop.type === initialFormState.type);
    setFilteredProperties(filtered);
  }, [availableProperties]);

  const handleFormChange = (key: string, value: number | boolean | string) => {
    setFormState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
    if (key === "type") {
      const filtered = availableProperties.filter((prop) => prop.type === value);      
      setFilteredProperties(filtered);
    }
  };

  const handleResetState = () => {
    setFormState(initialFormState);
    setIsBtnDisabled(true);
    setUrlErrors({ imprint: false, dataProtection: false });
  };

  const handleProductChange = (value: number) => {
    const product = productsApiData.find((prod) => prod.PID === value);
    setSelectedProduct(product);
    handleFormChange("product", value);
  };

  const handlePropertyChange = (value: string) => {
    const property = availableProperties.find((prop) => {
      const urlFallback = prop?.url || prop?.domain;
      return urlFallback === value;
    });
    setSelectedProperty(property);
    handleFormChange("install", value);
  };


  const formContent = (
    <Box>
      <Typography sx={{ padding: "16px 0" }}>{t("pages.buyNowComponent.startCreatingExperience")}</Typography>
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 3, paddingBottom: 1 }}>
        <FormControl fullWidth margin="dense">
          <InputLabel>{t("pages.buyNowComponent.typeLabel")}</InputLabel>
          <Select
            label={t("pages.buyNowComponent.typeLabel")}
            value={formState.type}
            onChange={(e) => handleFormChange("type", e.target.value)}
          >
            {componentsTypes.map((prop) => (
              <MenuItem key={prop.value} value={prop.value}>
                {prop.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControlLabel
          control={
            <Switch
              checked={formState.demo}
              onChange={(e) => handleFormChange("demo", e.target.checked)}
            />
          }
          label={t("pages.buyNowComponent.demoLabel")}
        />
      </Box>
      <FormControl fullWidth margin="dense" sx={{ paddingBottom: 1 }}>
        <InputLabel shrink>
          {filteredProperties?.length === 0 ? t("pages.buyNowComponent.noProperties") : t("pages.buyNowComponent.propertyLabel")}</InputLabel>
        <Select
          label={filteredProperties?.length === 0 ? t("pages.buyNowComponent.noProperties") : t("pages.buyNowComponent.propertyLabel")}
          value={formState.install}
          sx={{
            '& .MuiSelect-select': {
              color: formState.install ? 'inherit' : 'text.disabled', // Example customization
            },
          }}
          displayEmpty
          onChange={(e) => handlePropertyChange(e.target.value)}
          disabled={filteredProperties?.length === 0}
      >
          <MenuItem value="" disabled 
              sx={{
                '& .MuiSelect-select': {
                  color: theme.palette.text.disabled,
                },
              }}
            >
              {filteredProperties?.length === 0 ? t("pages.buyNowComponent.noProperties") : t("pages.buyNowComponent.propertyPlaceholder")}
            </MenuItem>
            {filteredProperties?.map((prop) => (
              <MenuItem key={prop.id} value={(prop?.url || prop.domain) as string}>
                {(prop?.url || na)}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl fullWidth margin="dense" sx={{ paddingBottom: 1 }}>
        <InputLabel shrink>{t("pages.buyNowComponent.leadingProductLabel")}</InputLabel>
          <Select
            label={t("pages.buyNowComponent.leadingProductLabel")}
            value={formState.product}
            onChange={(e) => handleProductChange(e.target.value as unknown as number)}
            displayEmpty
            sx={{
              '& .MuiSelect-select': {
                color: formState.product ? 'inherit' : 'text.disabled', // Example customization
              },
            }}
          >
            <MenuItem value="" disabled 
              sx={{
                '& .MuiSelect-select': {
                  color: theme.palette.text.disabled,
                },
              }}
            >
              {t("pages.buyNowComponent.leadingProductPlaceholder")}
            </MenuItem>
            {productsApi?.isLoading ? <MenuItem>Loading...</MenuItem> : productsApiData?.map((product) => (
              <MenuItem key={product.PID} value={product.PID}>
                {product.NAME} - {product.PZN}
              </MenuItem>
            ))}
          </Select>
      </FormControl>
      <Typography variant="h6" gutterBottom sx={{ padding: "12px 0" }}>
        {t("pages.buyNowComponent.imprintAndDataProtectionLabel")}
      </Typography>
      <TextField
        label={t("pages.buyNowComponent.imprintUrlLabel")}
        fullWidth
        margin="dense"
        value={formState.imprint}
        onChange={(e) => handleFormChange("imprint", e.target.value)}
        placeholder={t("pages.buyNowComponent.imprintUrlPlaceholder") as string}
        error={urlErrors.imprint}
        helperText={urlErrors.imprint ? t("pages.buyNowComponent.invalidUrlFormat") : ""}
        InputLabelProps={{ shrink: true }}
        sx={{ paddingBottom: 1 }}
      />
      <TextField
        label={t("pages.buyNowComponent.dataProtectionUrlLabel")}
        fullWidth
        margin="dense"
        value={formState.dataProtection}
        onChange={(e) => handleFormChange("dataProtection", e.target.value)}
        placeholder={t("pages.buyNowComponent.dataProtectionUrlPlaceholder") as string}
        error={urlErrors.dataProtection}
        helperText={urlErrors.dataProtection ? t("pages.buyNowComponent.invalidUrlFormat") : ""}
        InputLabelProps={{ shrink: true }}
      />
    </Box>
  );

  const onClose = () => {
    handleResetState();
    setDialogOpen(false);
  };

  return (
    <DynamicDialog
      open={dialogOpen}
      onClose={onClose}
      onClick={handleDialogClick}
      isLoading={isLoading}
      closeOnBackdropClick={true}
      title={t("pages.buyNowComponent.createComponentTitle")}
      content={formContent}
      primaryButtonText={t("pages.buyNowComponent.createButtonText") as string}
      isPrimaryBtnDisabled={isBtnDisabled}
      showSuccessOutside={true}
    />
  );
}

import { useApiQuery } from "../useApiQuery";
import { createQueryFunction } from "../queries/createQueryFunction";
import { BNFilterParams, BNFilterResponse, AllBNFilterApiType } from "./types";

const createBnApiQuery = (key: string, endpoint: string, type: number) => {
  return useApiQuery<BNFilterResponse[], BNFilterParams>({
    key,
    queryFn: createQueryFunction(`${endpoint}?type=${type}`),
  });
};

export const useBN = (): AllBNFilterApiType => {
  return {
    bnSourceApi: createBnApiQuery("bnSource", "promotions/utm_params", 0),
    bnMediumApi: createBnApiQuery("bnMedium", "promotions/utm_params", 1),
    bnCampaignApi: createBnApiQuery("bnCampaign", "promotions/utm_params", 2),
    bnContentApi: createBnApiQuery("bnContent", "promotions/utm_params", 3),
    bnTermApi: createBnApiQuery("bnTerm", "promotions/utm_params", 4),
    bnDeviceApi: createBnApiQuery("bnDevice", "promotions/filter_params", 0),
    bnUrlApi: createBnApiQuery("bnUrl", "promotions/filter_params", 1),
    bnInstanceApi: createBnApiQuery("bnInstance", "promotions/filter_params", 2),
    bnBannerApi: createBnApiQuery("bnBanner", "promotions/filter_params", 3),
  };
};
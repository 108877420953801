import { useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Grid, Tooltip, Typography, useTheme } from "@mui/material";
import useData from "@/hooks/auth/useData";
import InfoSvg from "@/assets/img/info.svg"
import { MuiGridTable as DataGridTable } from "@/components/tables/DataGridTable";;
import {
  BrandPerformanceResponse,
  MerchantLeadsResponse,
  MerchantsTransactionsResponse,
  ProductLeadsResponse,
} from "./types";
import {
  dependentAPIs,
  moreSelectors,
  selectorsConfig,
  useTableCells,
} from "./consts";
import AdvancedTable from "@/components/tables/AdvancedTable";
import Stats from "./components/Stats";
import { formatUserNumber } from "@/utils/formatUserNumber";
import FiltersPanel from "@/components/FiltersPanel";
import { LeadsTooltip } from "./components/LeadsTooltip";
import { VisitsTooltip } from "./components/VisitsTooltip";
import { KpiListSection } from "./components/KpiListSection";
import ContentContainer from "@/components/contentContainer";
import BarChart from "@/components/charts/barChart";
import { ChartData } from "chart.js";
import { GridColDef, GridSortDirection } from "@mui/x-data-grid-pro";
import { useColumns } from "@/hooks/useColumnsData";

function Analytics() {
  const { t } = useTranslation();
  const { user, ...analyticsApis } = useData();
  const allColumns = useColumns();
  const numberFormat = user?.settings?.numberFormat ?? "en-US";
  const visits = t("tableColumns.visits") || "";
  const opened = t("tableColumns.opened") || "";
  const leads = t("tableColumns.leads") || "";
  const transactions = t("tableColumns.transactions") || "";

  const theme = useTheme();
  const {
    productLeadsCells,
    productVisitsCells,
    brandVisitsCells,
    merchantsCells,
    merchantsTransactionsCells,
    productOpenedCells,
  } = useTableCells();

  const {
    analyticsGraphApi,
    productLeadsApi,
    productsVisitsApi,
    merchantsLeadsApi,
    merchantsTransactionsApi,
    brandPagePerformanceApi,
    kpiTransactionsApi,
    kpiBrandPerformanceApi,
    kpiMerchantsLeadsApi,
  } = analyticsApis;

  const graphData: ChartData<"bar"> = useMemo(() => {
    const data = analyticsGraphApi.data;

    const allDates = [
      ...(data?.conversions.map((point) => point.date) || []),
      ...(data?.leads.map((point) => point.date) || []),
      ...(data?.visits.map((point) => point.date) || []),
      ...(data?.opened?.map((point) => point.date) || []),
    ];

    const uniqueDates = Array.from(new Set(allDates)).sort((a, b) => a - b);
    const labels = uniqueDates.map((timestamp) =>
      formatUserNumber(
        new Date(timestamp * 1000),
        undefined,
        undefined,
        false,
        numberFormat
      )
    );
    return {
      labels: labels,
      datasets: [
        {
          label: visits,
          data:
            uniqueDates.map(
              (el) => data?.visits.find((el2) => el2.date === el)?.visits || 0
            ) || [],
          backgroundColor: "#d500fa",
          yAxisID: "y", // Use the left Y-axis
        },
        {
          label: opened,
          data:
            uniqueDates.map(
              (el) => data?.opened?.find((el2) => el2.date === el)?.opened || 0
            ) || [],
          backgroundColor: "#2096f3",
          yAxisID: "y", // Use the left Y-axis
        },
        {
          label: leads,
          data:
            uniqueDates.map(
              (el) => data?.leads.find((el2) => el2.date === el)?.leads || 0
            ) || [],
          backgroundColor: "#26a69a",
          yAxisID: "y1", // Use the right Y-axis
        },
        {
          label: transactions,
          data:
            uniqueDates.map(
              (el) =>
                data?.conversions.find((el2) => el2.date === el)?.conversions ||
                0
            ) || [],
          backgroundColor: "#7b20a2",
          yAxisID: "y1", // Use the right Y-axis
        },
      ],
    };
  }, [
    analyticsGraphApi.data,
    numberFormat,
    visits,
    leads,
    opened,
    transactions,
  ]);

  const updatedBrandPagePerformance = brandPagePerformanceApi.data?.map(
    (item: BrandPerformanceResponse) => {
      const CR = (item.LayerOpened / item.Visits) * 100;
      return {
        ...item,
        CR_numeric: CR,
        CR: `${formatUserNumber(CR, 1, 1)}%`,
      };
    }
  );

  const updateMerchantsLeads = merchantsLeadsApi.data?.map(
    (item: MerchantLeadsResponse) => {
      const CR = item.conversion_rate * 100;

      return {
        ...item,
        CR_numeric: CR,
        CR: `${formatUserNumber(CR, 1, 1)}% `,
      };
    }
  );

  const updateMerchantsTransactions = merchantsTransactionsApi.data?.map(
    (item: MerchantsTransactionsResponse) => {
      const CR = item.conversion_rate * 100;
      return {
        ...item,
        transactions: Math.round(item.transactions),
        CR_numeric: CR,
        CR: `${formatUserNumber(CR, 1, 1)}%`,
      };
    }
  );

  const updateProductLeads = productLeadsApi.data?.map(
    (item: ProductLeadsResponse, index: number) => {
      const CR = item.CR * 100;
      return {
        ...item,
        id: `product-lead-${index}`,
        CR_numeric: CR,
        CR: CR,
      };
    }
  );

  const sortModel = useMemo(
    () => [
      {
        field: "LEADS",
        sort: "desc" as GridSortDirection,
      },
    ],
    []
  );

  const columns: GridColDef<ProductLeadsResponse>[] = [
    {
      ...allColumns.leadsColumn,
      width: 80,
    },
    {
      ...allColumns.conversionRateColumn,
      width: 80,
    },
    {
      ...allColumns.productLinkColumn,
      flex: 1,  // Makes this column expandable
      minWidth: 100,  // Sets the minimum width to 100
    },
    { ...allColumns.sizeColumn, width: 100 },
    { ...allColumns.pznColumn, width: 100 },
    allColumns.posColumn,
  ];

  return (
    <ContentContainer>
      <FiltersPanel
        selectorsConfig={selectorsConfig}
        dependentAPIs={dependentAPIs}
        onMoreSelectors={moreSelectors}
      />
      <Grid container spacing={theme.gap}>
        <Grid item xs={12} lg={12}>
          <BarChart
            data={graphData}
            loading={
              analyticsGraphApi.isLoading || !analyticsGraphApi.isExecuted
            }
            yTitle={`${visits}/${opened}`}
            y1Title={`${leads}/${transactions}`}
            graphPadding="10px 0"
            startStackingFromZero={true}
            isError={analyticsGraphApi.isError}
            error={analyticsGraphApi.error}
          />
        </Grid>
      </Grid>
      <Grid container spacing={theme.gap}>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Grid container rowSpacing={"2px"} columnSpacing={"1px"}>
            <Grid item xs={12} sm={12}>
              <Stats
                title={visits}
                amount={formatUserNumber(
                  kpiBrandPerformanceApi.data?.visits || 0
                )}
                tooltip={visits}
                isLoading={kpiBrandPerformanceApi.isLoading}
                isError={kpiBrandPerformanceApi.isError}
                error={kpiBrandPerformanceApi.error}
              />
            </Grid>
            <KpiListSection
              title={t("pages.analytics.VisitsPerBrand")}
              tooltip={t("pages.analytics.tooltip.VisitsPerBrand")}
              headCells={brandVisitsCells}
              rows={brandPagePerformanceApi.data || []}
              initialSortBy="Visits"
              progressBar="Visits"
              isLoading={brandPagePerformanceApi.isLoading}
              filterColumn="Brand"
              isError={brandPagePerformanceApi.isError}
              error={brandPagePerformanceApi.error}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Grid container rowSpacing={"2px"} columnSpacing={"1px"}>
            <Grid item xs={12} sm={6}>
              <Stats
                title={opened}
                amount={formatUserNumber(
                  kpiBrandPerformanceApi.data?.layer_opened || 0
                )}
                tooltip={opened}
                isLoading={kpiBrandPerformanceApi.isLoading}
                isError={kpiBrandPerformanceApi.isError}
                error={kpiBrandPerformanceApi.error}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stats
                title={t("tableColumns.openRate")}
                amount={`${formatUserNumber(
                  (kpiBrandPerformanceApi.data?.conversion_rate || 0) * 100
                )}%`}
                tooltip={t("tableColumns.openRate")}
                isLoading={kpiBrandPerformanceApi.isLoading}
                isError={kpiBrandPerformanceApi.isError}
                error={kpiBrandPerformanceApi.error}
              />
            </Grid>
            <KpiListSection
              title={t("pages.analytics.LayerOpenedBrand")}
              tooltip={t("pages.analytics.tooltip.LayerOpenedBrand")}
              headCells={productOpenedCells}
              rows={updatedBrandPagePerformance || []}
              initialSortBy="LayerOpened"
              progressBar="LayerOpened"
              isLoading={brandPagePerformanceApi.isLoading}
              filterColumn="Brand"
              isError={brandPagePerformanceApi.isError}
              error={brandPagePerformanceApi.error}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Grid container rowSpacing={"2px"} columnSpacing={"1px"}>
            <Grid item xs={12} sm={6}>
              <Stats
                title={leads}
                amount={formatUserNumber(kpiMerchantsLeadsApi.data?.leads || 0)}
                tooltip={leads}
                isLoading={kpiMerchantsLeadsApi.isLoading}
                isError={kpiMerchantsLeadsApi.isError}
                error={kpiMerchantsLeadsApi.error}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stats
                title={t("tableColumns.CTR")}
                amount={`${formatUserNumber(
                  (kpiMerchantsLeadsApi.data?.conversion_rate || 0) * 100
                )}%`}
                tooltip={t("pages.analytics.tooltip.CTR")}
                isLoading={kpiMerchantsLeadsApi.isLoading}
                isError={kpiMerchantsLeadsApi.isError}
                error={kpiMerchantsLeadsApi.error}
              />
            </Grid>
            <KpiListSection
              isError={kpiMerchantsLeadsApi.isError}
              title={t("pages.analytics.MerchantLeads")}
              tooltip={t("pages.analytics.tooltip.MerchantLeads")}
              headCells={merchantsCells}
              rows={updateMerchantsLeads || []}
              initialSortBy="leads"
              progressBar="leads"
              isLoading={merchantsLeadsApi.isLoading}
              filterColumn="merchant"
              error={kpiMerchantsLeadsApi.error}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Grid container rowSpacing={"2px"} columnSpacing={"1px"}>
            <Grid item xs={12} sm={6}>
              <Stats
                title={transactions}
                amount={formatUserNumber(
                  Math.round(kpiTransactionsApi.data?.transactions || 0)
                )}
                tooltip={transactions}
                isLoading={kpiTransactionsApi.isLoading}
                isError={kpiTransactionsApi.isError}
                error={kpiTransactionsApi.error}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stats
                title={t("tableColumns.conversion")}
                amount={`${formatUserNumber(
                  (kpiTransactionsApi.data?.conversion_rate || 0) * 100
                )}%`}
                tooltip={t("tableColumns.conversion")}
                isLoading={kpiTransactionsApi.isLoading}
                isError={kpiTransactionsApi.isError}
                error={kpiTransactionsApi.error}
              />
            </Grid>
            <KpiListSection
              title={t("pages.analytics.TransactionsLeads")}
              tooltip={t("pages.analytics.tooltip.TransactionsLeads")}
              headCells={merchantsTransactionsCells}
              rows={updateMerchantsTransactions || []}
              initialSortBy="transactions"
              progressBar="transactions"
              isLoading={merchantsTransactionsApi.isLoading}
              filterColumn="merchant"
              isError={merchantsTransactionsApi.isError}
              error={merchantsTransactionsApi.error}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={theme.gap}>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={3}>
          <KpiListSection
            title={t("pages.analytics.VisitsProduct")}
            tooltip={<VisitsTooltip />}
            headCells={productVisitsCells}
            rows={productsVisitsApi.data || []}
            initialSortBy="visits"
            progressBar="visits"
            isLoading={productsVisitsApi.isLoading}
            filterColumn="product"
            isError={productsVisitsApi.isError}
            error={productsVisitsApi.error}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={9} sx={{ position: "relative" }}>
          <Typography
            variant="h6"
            sx={{
              backgroundColor: theme.palette.background.paper,
            }}
            pt={"16px"}
            pl={"16px"}
            pb={"16px"}
          >
            {t("pages.analytics.ProductLeads")}
            <Tooltip
              title={<LeadsTooltip />}
              style={{
                cursor: "pointer",
                position: "absolute",
                top: "30px",
                right: "16px",
              }}
              placement="top"
            >
              <img src={InfoSvg} alt="info" />
            </Tooltip>
          </Typography>

          <div style={{ backgroundColor: theme.palette.background.paper }}>
            <DataGridTable
              rows={updateProductLeads || []}
              columns={columns}
              loading={productLeadsApi.isLoading}
              hasControls={false}
              sortModel={sortModel}
            />
          </div>
        </Grid>
      </Grid>
    </ContentContainer>
  );
}

export default Analytics;

import {ErrorCallback, useApiMutation} from "@/api/useApiMutation";
import {ComponentsResponse, Config} from "@/api/buynow/types";
import {createMutationFunction} from "@/api/mutations/createMutationFunction";
import {t} from "i18next";

const basePath = "app/buy-now/"


export interface CreateConfigParams {
  manufacturer_id: number;
  config: { [key: string]: any };
  comment: string;
  status: number;
  type?: number;
}

export interface ConfigResponse {
  CID: number;
  MANUFACTURER_ID: string;
  CONFIG: { [key: string]: any };
  STATUS: number;
  COMMENT: string;
  CREATED: string;
  UPDATED: string;
}

const handleCustomError = ({
  error,
  setError,
  setData,
}: ErrorCallback<ConfigResponse>) => {
  const errorMessage =
    error.response && error.response.status === 400
      ? t("user.updateError")
      : `${t("errors.globalError")}: ${error.message}`;

  setError(errorMessage);
  setData(undefined);
};

export const useCreateConfig = () => {
  return useApiMutation<ConfigResponse, CreateConfigParams>({
    mutationFn:
      createMutationFunction<ConfigResponse, CreateConfigParams>(
        `${basePath}configs`),
    onErrorCallback: handleCustomError,
  });
};
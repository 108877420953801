import { useState, useEffect, useRef, useMemo, useLayoutEffect } from "react";
import useData from "@/hooks/auth/useData";
import {
  AnalyticsRequestBody,
  SelectorDef,
} from "@/components/FiltersPanel/types";
import { useTranslation } from "react-i18next";
import { SelectorConfig } from "@/components/FiltersPanel/types";
import { SelectorNames } from "@/components/FiltersPanel/consts";
import { useNavigate } from "react-router-dom";
import { DataState } from "@/types/dataContext";
import { defaultSelectors as panelDefaults } from "@/components/FiltersPanel/consts";
import { Option } from "@/types/selectorOption";
import { MerchantsResponse } from "@/api/useMerchants";
import useCustomTableUtils from "./useColumnsData/utils";

type EventType = Option[] | null | undefined;

export const useFiltersData = (
  selectorsConfig: SelectorDef[],
  dependentAPIs: (keyof DataState)[]
) => {
  const {
    manufacturersApi,
    user,
    selectedManufacturers,
    merchantsApi,
    dateRange,
    daysChanged,
    setFiltersLoading,
    ...analyticsApis
  } = useData();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { avTranslations } = useCustomTableUtils();

  const [loadingFilters, setLoadingFilters] = useState<Array<string>>([]);
  const hasExecutedInitialLoad = useRef(false);
  const hasManuallyChanged = useRef(false);
  const manufacturersData = manufacturersApi?.manufacturers?.data || [];
  const utmParamsNames = [
    SelectorNames.Source,
    SelectorNames.Medium,
    SelectorNames.Campaign,
    SelectorNames.Term,
    SelectorNames.Content,
    SelectorNames.Device,
    SelectorNames.Url,
    SelectorNames.Component,
    SelectorNames.Button,
  ];
  const hasManufacturerSelector = selectorsConfig.find(
    (sc) => sc.name === SelectorNames.Manufacturer
  );

  const selectedManufacturerIDs = selectedManufacturers.map((m) => m.ID);

  const getDefaultSelectorValue = (
    name: string,
    defaultValue?: string | number | (string | number)[]
  ) => {
    if (defaultValue !== undefined) {
      return Array.isArray(defaultValue) ? defaultValue : [defaultValue];
    }
    return panelDefaults[name as keyof AnalyticsRequestBody] || [];
  };

  const defaultSelectors: Record<string, (string | number)[] | number> =
    selectorsConfig.reduce((acc, selector) => {
      acc[selector.name] = getDefaultSelectorValue(
        selector.name,
        selector.defaultValue
      );
      return acc;
    }, {} as Record<string, (string | number)[] | number>);

  const getURLParams = () => {
    const params = new URLSearchParams(window.location.search);
    const urlParams: Record<string, string | number | Array<string | number>> =
      {};

    const queryParams = selectorsConfig.map((sc) => sc.name);

    queryParams.forEach((param) => {
      const value = params.get(param);
      if (value !== null) {
        urlParams[param] = value
          .split(",")
          // keep leading zeros
          .map((v) => (!/^0\d+$/.test(v) && !isNaN(Number(v)) && v.trim() !== "" ? Number(v) : v));
      }
    });
    
    return urlParams;
  };

  const [currentSelectors, setCurrentSelectors] =
    useState<AnalyticsRequestBody>({
      ...defaultSelectors,
      manufacturers: selectedManufacturerIDs,
      ...getURLParams(),
      from_date: dateRange.from_date,
      to_date: dateRange.to_date,
    });

  const executeApi = (
    selectorName: string,
    selectors: AnalyticsRequestBody
  ) => {
    const selectorManufacturer = Array.isArray(selectors.manufacturers)
      ? selectors.manufacturers
      : [selectors.manufacturers || 0];
    const manufacturers = hasManufacturerSelector
      ? selectorManufacturer
      : selectedManufacturerIDs;

    switch (selectorName) {
      case SelectorNames.Products:
        return analyticsApis.productsApi.execute({
          m: manufacturers,
          b: selectors.brands || [],
          sb: selectors.subbrands || [],
          f: selectors.focus || [0],
        });
      case SelectorNames.ParentBrand:
        return analyticsApis.parentBrandsApi?.execute({
          m: manufacturers,
          f: selectors.focus || [0],
        });
      case SelectorNames.SubBrand:
        return analyticsApis.subBrandsApi?.execute({
          m: manufacturers,
          b: selectors.brands,
          f: selectors.focus || [0],
        });
      case SelectorNames.Seller:
        return analyticsApis.sellerApi?.execute({
          m: manufacturers,
          pos: selectors.merchants,
        });
      case SelectorNames.Label:
        return analyticsApis.labelsApi?.execute();
      case SelectorNames.UtmParams:
        const payload = {
          m: manufacturers,
          from_date: selectors.from_date,
          to_date: selectors.to_date,
        }
        return Promise.all([
          analyticsApis.bnSourceApi.execute(payload),
          analyticsApis.bnMediumApi.execute(payload),
          analyticsApis.bnCampaignApi.execute(payload),
          analyticsApis.bnContentApi.execute(payload),
          analyticsApis.bnTermApi.execute(payload),
          analyticsApis.bnDeviceApi.execute(payload),
          analyticsApis.bnUrlApi.execute(payload),
          analyticsApis.bnInstanceApi.execute(payload),
          analyticsApis.bnBannerApi.execute(payload),
        ]);
      default:
        return Promise.resolve();
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    Object.keys(currentSelectors).forEach((key) => {
      const typedKey = key as keyof AnalyticsRequestBody;
      if (
        key !== "manufacturers" &&
        key !== "from_date" &&
        key !== "to_date" &&
        key !== "days"
      ) {
        const c = currentSelectors[typedKey];

        if (c && Array.isArray(c) && c.length > 0) {
          const cString = c.join(",");
          if (cString) {
            params.set(key, cString);
          } else {
            params.delete(key);
          }
        } else {
          params.delete(key);
        }
      }
    });

    const paramString = params.toString();
    const url = paramString
      ? `${window.location.pathname}?${paramString}`
      : window.location.pathname;

    navigate(url, { replace: true });
  }, [currentSelectors]);

  const fetchData = async (updatedSelectors: AnalyticsRequestBody) => {
    try {
      setFiltersLoading(true);
      const filterNames = selectorsConfig.map((s) =>
        utmParamsNames.includes(s.name as SelectorNames)
          ? SelectorNames.UtmParams
          : s.name
      );

      setLoadingFilters(filterNames);

      await Promise.all(
        selectorsConfig.map((selector) => {
          const selectorName = utmParamsNames.includes(
            selector.name as SelectorNames
          )
            ? SelectorNames.UtmParams
            : selector.name;

          executeApi(selectorName, updatedSelectors);
        })
      );

      fetchDependentAPIs(updatedSelectors);
    } catch (error) {
      console.error("API call failed", error);
    } finally {
      setFiltersLoading(false);
      hasExecutedInitialLoad.current = true;
      setLoadingFilters([]);
    }
  };

  useLayoutEffect(() => {
    setFiltersLoading(true);

    return () => {
      setFiltersLoading(false);
    }
  }, []);

  useEffect(() => {
    if (
      selectedManufacturers.length > 0 &&
      (!hasExecutedInitialLoad.current || !hasManufacturerSelector)
    ) {
      let updatedSelectors = {
        ...currentSelectors,
        manufacturers: selectedManufacturerIDs,
        from_date: dateRange.from_date,
        to_date: dateRange.to_date,
      };

      // only happens if no manufacturers filter and top level manufacturer changed
      if (hasExecutedInitialLoad.current) {
        updatedSelectors = {
          ...defaultSelectors,
          manufacturers: selectedManufacturerIDs,
          from_date: dateRange.from_date,
          to_date: dateRange.to_date,
        };
      }

      setCurrentSelectors(updatedSelectors);

      fetchData(updatedSelectors);
    }
  }, [selectedManufacturers]);

  useEffect(() => {
    if (dateRange && daysChanged.current) {
      const updatedSelectors = {
        ...currentSelectors,
        from_date: dateRange.from_date,
        to_date: dateRange.to_date,
        // for simplicity: reset utm filters if time changes (for now)
        urls: [],
        devices: [],
        utm_terms: [],
        utm_sources: [],
        utm_mediums: [],
        utm_campaigns: [],
        utm_contents: [],
        instances: [],
        banners: [],
      };

      setCurrentSelectors(updatedSelectors);

      fetchDependentAPIs(updatedSelectors, true);

      daysChanged.current = false;
    }
  }, [dateRange]);

  const fetchDependentAPIs = async (selectors: AnalyticsRequestBody, timeChanged: boolean = false) => {
    if (!hasManufacturerSelector) {
      selectors = {
        ...selectors,
        manufacturers: selectedManufacturerIDs,
      };
    }
    try {
      setFiltersLoading(true);
      // @ts-ignore
      const apiCalls = dependentAPIs.map((api) => analyticsApis[api]?.execute(selectors));
      if(timeChanged) {
        const hasUTMFilters = selectorsConfig.find(
          (sc) => utmParamsNames.includes(sc.name as SelectorNames)
        );
        if(hasUTMFilters) {
          const payload = {
            m: selectors.manufacturers,
            from_date: selectors.from_date,
            to_date: selectors.to_date,
          }
          apiCalls.push(
            analyticsApis.bnSourceApi.execute(payload),
            analyticsApis.bnMediumApi.execute(payload),
            analyticsApis.bnCampaignApi.execute(payload),
            analyticsApis.bnContentApi.execute(payload),
            analyticsApis.bnTermApi.execute(payload),
            analyticsApis.bnDeviceApi.execute(payload),
            analyticsApis.bnUrlApi.execute(payload),
            analyticsApis.bnInstanceApi.execute(payload),
            analyticsApis.bnBannerApi.execute(payload),
          );
          
        }
      }
      await Promise.all(apiCalls);
    } catch (error) {
      console.error("Filter API call failed", error);
    } finally {
      setFiltersLoading(false);
    }
  };

  const handleFilterChange = async (
    selector: Array<string>,
    data: EventType,
    field: keyof AnalyticsRequestBody
  ) => {
    setFiltersLoading(true);
    const defaultSelectorValue =
      defaultSelectors[field as keyof AnalyticsRequestBody];
    const newData =
      data === null || data === undefined
        ? defaultSelectorValue
        : data.map((d) => d.value);

    const updatedSelectors = {
      ...currentSelectors,
      [field]: newData,
    };

    setLoadingFilters((prevItems) => {
      return Array.from(new Set([...prevItems, ...selector]));
    });

    setCurrentSelectors(updatedSelectors);

    await Promise.all(
      selector.map((api) => executeApi(api, updatedSelectors))
    ).then(() => {
      hasManuallyChanged.current = true;
    });
  };

  const handleManufacturerChange = async (
    value: { value: string; label: string }[] | null,
    name: string
  ) => {
    const updatedSelectors = { ...currentSelectors };

    if (name === SelectorNames.Manufacturer) {
      updatedSelectors.manufacturers = [Number(value)];
    }

    setCurrentSelectors(updatedSelectors);

    await Promise.all(
      selectorsConfig.map((selector) => {
        const selectorName = utmParamsNames.includes(
          selector.name as SelectorNames
        )
          ? SelectorNames.UtmParams
          : selector.name;
        return executeApi(selectorName, updatedSelectors);
      })
    );
    await fetchDependentAPIs(updatedSelectors);
  };

  const optionsAvailability = Object.keys(avTranslations).map((key) => ({
    value: parseInt(key),
    label: avTranslations[key as unknown as keyof typeof avTranslations],
    key: parseInt(key),
  }));

  // @ts-ignore
  const commonSelectorsConfig: SelectorConfig[] = useMemo(() => {
    return selectorsConfig.map((selector) => {
      switch (selector.name) {
        case SelectorNames.Manufacturer:
          return {
            label: t("filters.manufacturer"),
            name: SelectorNames.Manufacturer,
            options: manufacturersData.map((manufacturer) => ({
              value: manufacturer.ID,
              label: manufacturer.NAME,
              key: manufacturer.ID,
            })),
            handleChange: handleManufacturerChange,
            hasAllOption:
              selector?.hasAllOption !== undefined
                ? selector?.hasAllOption
                : false,
            multiSelect:
              selector?.multiSelect !== undefined
                ? selector?.multiSelect
                : false,
            isLoading:
              manufacturersApi?.manufacturers.isLoading ||
              manufacturersApi?.manufacturers.isExecuted,
          };
        case SelectorNames.Merchant:
          return {
            label: t("filters.merchant"),
            name: SelectorNames.Merchant,
            options: (Array.isArray(merchantsApi?.merchants.data)
              ? merchantsApi?.merchants.data
              : []
            ).map((merchant: MerchantsResponse) => ({
              value: merchant.ID,
              label: merchant.NAME,
              key: merchant.ID,
              property: merchant.IS_PRIMARY && !merchant.IS_MARKET ? "primary" : "secondary"
            })),
            handleChange: (event: EventType) =>
              handleFilterChange(
                selector?.dependentFilters || [],
                event,
                SelectorNames.Merchant
              ),
            defaultValue: selector?.defaultValue,
            hasAllOption:
              selector?.hasAllOption !== undefined
                ? selector?.hasAllOption
                : true,
            multiSelect:
              selector?.multiSelect !== undefined
                ? selector?.multiSelect
                : true,
            isLoading:
              merchantsApi?.merchants.isLoading ||
              !merchantsApi?.merchants.isExecuted ||
              loadingFilters.includes(SelectorNames.Merchant),
          };
        case SelectorNames.Products:
          return {
            label: t("filters.product"),
            name: SelectorNames.Products,
            options:
              analyticsApis.productsApi.data?.map((product) => ({
                value: product.PID,
                label: `${product.NAME} - ${product.PZN}`,
                key: product.PID,
              })) || [],
            dependentOn: SelectorNames.ParentBrand,
            handleChange: (event: EventType) =>
              handleFilterChange(
                selector?.dependentFilters || [],
                event,
                SelectorNames.Products
              ),
            labelColor: selector?.labelColor || "#1565C0",
            defaultValue: selector?.defaultValue,
            hasAllOption:
              selector?.hasAllOption !== undefined
                ? selector?.hasAllOption
                : true,
            multiSelect:
              selector?.multiSelect !== undefined
                ? selector?.multiSelect
                : true,
            isLoading:
              analyticsApis.productsApi.isLoading ||
              !analyticsApis.productsApi.isExecuted ||
              loadingFilters.includes(SelectorNames.Products),
          };
        case SelectorNames.Focus:
          return {
            label: t("filters.focus"),
            name: SelectorNames.Focus,
            options: [
              {
                value: 0,
                label: t("pages.analytics.selectors.no"),
                key: 0,
              },
              {
                value: 1,
                label: t("pages.analytics.selectors.yes"),
                key: 1,
              },
            ],
            handleChange: (event: EventType) =>
              handleFilterChange(
                selector?.dependentFilters || [],
                event,
                SelectorNames.Focus
              ),
            labelColor: selector?.labelColor || "#1565C0",
            defaultValue: selector?.defaultValue || 0,
            hasAllOption:
              selector?.hasAllOption !== undefined
                ? selector?.hasAllOption
                : false,
            multiSelect:
              selector?.multiSelect !== undefined
                ? selector?.multiSelect
                : false,
            isLoading: loadingFilters.includes(SelectorNames.Focus),
          };
        case SelectorNames.AvStatus:
          return {
            label: t("filters.status"),
            name: SelectorNames.AvStatus,
            options: [
              {
                value: 1,
                label: t("pages.analytics.selectors.active"),
                key: 1,
              },
              {
                value: 2,
                label: t("pages.analytics.selectors.discontinued"),
                key: 2,
              },
            ],
            handleChange: (event: EventType) =>
              handleFilterChange(
                selector?.dependentFilters || [],
                event,
                SelectorNames.AvStatus
              ),
            labelColor: selector?.labelColor,
            defaultValue: selector?.defaultValue || 0,
            hasAllOption:
              selector?.hasAllOption !== undefined
                ? selector?.hasAllOption
                : true,
            multiSelect:
              selector?.multiSelect !== undefined
                ? selector?.multiSelect
                : false,
            isLoading: loadingFilters.includes(SelectorNames.AvStatus),
          };
        case SelectorNames.RevStatus:
          return {
            label: t("filters.revStatus"),
            name: SelectorNames.RevStatus,
            options: [
              {
                value: 1,
                label: "Active",
                key: 1,
              },
              {
                value: 2,
                label: "Deleted",
                key: 2,
              },
            ],
            handleChange: (event: EventType) =>
              handleFilterChange(
                selector?.dependentFilters || [],
                event,
                SelectorNames.RevStatus
              ),
            defaultValue: selector?.defaultValue,
            hasAllOption:
              selector?.hasAllOption !== undefined
                ? selector?.hasAllOption
                : true,
            multiSelect:
              selector?.multiSelect !== undefined
                ? selector?.multiSelect
                : false,
            isLoading: loadingFilters.includes(SelectorNames.RevStatus),
          };
        case SelectorNames.Source:
          return {
            label: t("filters.source"),
            name: SelectorNames.Source,
            options: analyticsApis.bnSourceApi.data
              ? analyticsApis.bnSourceApi.data.map((source) => ({
                  value: source.id,
                  label: source.value,
                  key: source.id,
                }))
              : [],
            handleChange: (event: EventType) =>
              handleFilterChange(
                selector?.dependentFilters || [],
                event,
                SelectorNames.Source
              ),
            labelColor: selector?.labelColor || "#59a74f",
            dependentOn: SelectorNames.Manufacturer,
            hasAllOption:
              selector?.hasAllOption !== undefined
                ? selector?.hasAllOption
                : true,
            multiSelect:
              selector?.multiSelect !== undefined
                ? selector?.multiSelect
                : true,
            isLoading:
              analyticsApis.bnSourceApi.isLoading ||
              !analyticsApis.bnSourceApi.isExecuted ||
              loadingFilters.includes(SelectorNames.Source) ||
              loadingFilters.includes(SelectorNames.UtmParams),
          };
        case SelectorNames.Medium:
          return {
            label: t("filters.medium"),
            name: SelectorNames.Medium,
            options: analyticsApis.bnMediumApi.data
              ? analyticsApis.bnMediumApi.data.map((medium) => ({
                  value: medium.id,
                  label: medium.value,
                  key: medium.id,
                }))
              : [],
            handleChange: (event: EventType) =>
              handleFilterChange(
                selector?.dependentFilters || [],
                event,
                SelectorNames.Medium
              ),
            dependentOn: SelectorNames.Manufacturer,
            labelColor: selector?.labelColor || "#59a74f",
            defaultValue: selector?.defaultValue,
            hasAllOption:
              selector?.hasAllOption !== undefined
                ? selector?.hasAllOption
                : true,
            multiSelect:
              selector?.multiSelect !== undefined
                ? selector?.multiSelect
                : true,
            isLoading:
              analyticsApis.bnMediumApi.isLoading ||
              !analyticsApis.bnMediumApi.isExecuted ||
              loadingFilters.includes(SelectorNames.Medium) ||
              loadingFilters.includes(SelectorNames.UtmParams),
          };
        case SelectorNames.Campaign:
          return {
            label: t("filters.campaign"),
            name: SelectorNames.Campaign,
            options: analyticsApis.bnCampaignApi.data
              ? analyticsApis.bnCampaignApi.data.map(
                  (campaign) => ({
                    value: campaign.id,
                    label: campaign.value,
                    key: campaign.id,
                  })
                )
              : [],
            handleChange: (event: EventType) =>
              handleFilterChange(
                selector?.dependentFilters || [],
                event,
                SelectorNames.Campaign
              ),
            dependentOn: SelectorNames.Manufacturer,
            labelColor: selector?.labelColor || "#59a74f",
            defaultValue: selector?.defaultValue,
            hasAllOption:
              selector?.hasAllOption !== undefined
                ? selector?.hasAllOption
                : true,
            multiSelect:
              selector?.multiSelect !== undefined
                ? selector?.multiSelect
                : true,
            isLoading:
              analyticsApis.bnCampaignApi.isLoading ||
              !analyticsApis.bnCampaignApi.isExecuted ||
              loadingFilters.includes(SelectorNames.Campaign) ||
              loadingFilters.includes(SelectorNames.UtmParams),
          };
        case SelectorNames.Term:
          return {
            label: t("filters.term"),
            name: SelectorNames.Term,
            options: analyticsApis.bnTermApi.data
              ? analyticsApis.bnTermApi.data.map((term) => ({
                  value: term.id,
                  label: term.value,
                  key: term.id,
                }))
              : [],
            handleChange: (event: EventType) =>
              handleFilterChange(
                selector?.dependentFilters || [],
                event,
                SelectorNames.Term
              ),
            dependentOn: SelectorNames.Manufacturer,
            labelColor: selector?.labelColor || "#59a74f",
            defaultValue: selector?.defaultValue,
            hasAllOption:
              selector?.hasAllOption !== undefined
                ? selector?.hasAllOption
                : true,
            multiSelect:
              selector?.multiSelect !== undefined
                ? selector?.multiSelect
                : true,
            isLoading:
              analyticsApis.bnTermApi.isLoading ||
              !analyticsApis.bnTermApi.isExecuted ||
              loadingFilters.includes(SelectorNames.Term) ||
              loadingFilters.includes(SelectorNames.UtmParams),
          };
        case SelectorNames.Content:
          return {
            label: t("filters.content"),
            name: SelectorNames.Content,
            options: analyticsApis.bnContentApi.data
              ? analyticsApis.bnContentApi.data.map((content) => ({
                  value: content.id,
                  label: content.value,
                  key: content.id,
                }))
              : [],
            handleChange: (event: EventType) =>
              handleFilterChange(
                selector?.dependentFilters || [],
                event,
                SelectorNames.Content
              ),

            dependentOn: SelectorNames.Manufacturer,
            labelColor: selector?.labelColor || "#59a74f",
            defaultValue: selector?.defaultValue,
            hasAllOption:
              selector?.hasAllOption !== undefined
                ? selector?.hasAllOption
                : true,
            multiSelect:
              selector?.multiSelect !== undefined
                ? selector?.multiSelect
                : true,
            isLoading:
              analyticsApis.bnContentApi.isLoading ||
              !analyticsApis.bnContentApi.isExecuted ||
              loadingFilters.includes(SelectorNames.Content) ||
              loadingFilters.includes(SelectorNames.UtmParams),
          };
        case SelectorNames.Device:
          return {
            label: t("filters.device"),
            name: SelectorNames.Device,
            options: analyticsApis.bnDeviceApi.data
              ? analyticsApis.bnDeviceApi.data.map(
                  (device) => ({
                    value: device.id,
                    label: device.value,
                    key: device.id,
                  })
                )
              : [],
            handleChange: (event: EventType) =>
              handleFilterChange(
                selector?.dependentFilters || [],
                event,
                SelectorNames.Device
              ),
            dependentOn: SelectorNames.Manufacturer,
            labelColor: selector?.labelColor,
            defaultValue: selector?.defaultValue,
            hasAllOption:
              selector?.hasAllOption !== undefined
                ? selector?.hasAllOption
                : true,
            multiSelect:
              selector?.multiSelect !== undefined
                ? selector?.multiSelect
                : true,
            isLoading:
              analyticsApis.bnDeviceApi.isLoading ||
              !analyticsApis.bnDeviceApi.isExecuted ||
              loadingFilters.includes(SelectorNames.Device) ||
              loadingFilters.includes(SelectorNames.UtmParams),
          };
        case SelectorNames.Url:
          return {
            label: t("filters.url"),
            name: SelectorNames.Url,
            options: analyticsApis.bnUrlApi.data
              ? analyticsApis.bnUrlApi.data.map((url) => ({
                  value: url.id,
                  label: url.value,
                  key: url.id,
                }))
              : [],
            handleChange: (event: EventType) =>
              handleFilterChange(
                selector?.dependentFilters || [],
                event,
                SelectorNames.Url
              ),
            labelColor: selector?.labelColor,
            dependentOn: SelectorNames.Manufacturer,
            defaultValue: selector?.defaultValue,
            hasAllOption:
              selector?.hasAllOption !== undefined
                ? selector?.hasAllOption
                : true,
            multiSelect:
              selector?.multiSelect !== undefined
                ? selector?.multiSelect
                : true,
            isLoading:
              analyticsApis.bnUrlApi.isLoading ||
              !analyticsApis.bnUrlApi.isExecuted ||
              loadingFilters.includes(SelectorNames.Url) ||
              loadingFilters.includes(SelectorNames.UtmParams),
          };
        case SelectorNames.Component:
          return {
            label: t("filters.component"),
            name: SelectorNames.Component,
            options: analyticsApis.bnInstanceApi.data
              ? analyticsApis.bnInstanceApi.data.map((comp) => ({
                  value: comp.id,
                  label: comp.value,
                  key: comp.id,
                }))
              : [],
            handleChange: (event: EventType) =>
              handleFilterChange(
                selector?.dependentFilters || [],
                event,
                SelectorNames.Component
              ),
            labelColor: selector?.labelColor,
            dependentOn: SelectorNames.Manufacturer,
            defaultValue: selector?.defaultValue,
            hasAllOption:
              selector?.hasAllOption !== undefined
                ? selector?.hasAllOption
                : true,
            multiSelect:
              selector?.multiSelect !== undefined
                ? selector?.multiSelect
                : true,
            isLoading:
              analyticsApis.bnInstanceApi.isLoading ||
              !analyticsApis.bnInstanceApi.isExecuted ||
              loadingFilters.includes(SelectorNames.Component) ||
              loadingFilters.includes(SelectorNames.UtmParams),
          };
        case SelectorNames.Button:
          return {
            label: t("filters.button"),
            name: SelectorNames.Button,
            options: analyticsApis.bnBannerApi.data
              ? analyticsApis.bnBannerApi.data.map((banner) => ({
                  value: banner.id,
                  label: banner.value,
                  key: banner.id,
                }))
              : [],
            handleChange: (event: EventType) =>
              handleFilterChange(
                selector?.dependentFilters || [],
                event,
                SelectorNames.Button
              ),
            labelColor: selector?.labelColor,
            dependentOn: SelectorNames.Manufacturer,
            defaultValue: selector?.defaultValue,
            hasAllOption:
              selector?.hasAllOption !== undefined
                ? selector?.hasAllOption
                : true,
            multiSelect:
              selector?.multiSelect !== undefined
                ? selector?.multiSelect
                : true,
            isLoading:
              analyticsApis.bnBannerApi.isLoading ||
              !analyticsApis.bnBannerApi.isExecuted ||
              loadingFilters.includes(SelectorNames.Button) ||
              loadingFilters.includes(SelectorNames.UtmParams),
        };
        case SelectorNames.ParentBrand:
          return {
            label: t("filters.brand"),
            name: SelectorNames.ParentBrand,
            options: (analyticsApis.parentBrandsApi?.data || []).map(
              (brand) => ({
                value: brand.ID,
                label: brand.BRAND,
                key: brand.ID,
              })
            ),
            handleChange: (event: EventType) =>
              handleFilterChange(
                selector?.dependentFilters || [
                  SelectorNames.Products,
                  SelectorNames.SubBrand,
                ],
                event,
                SelectorNames.ParentBrand
              ),
            labelColor: selector?.labelColor || "#1565C0",
            defaultValue: selector?.defaultValue,
            hasAllOption:
              selector?.hasAllOption !== undefined
                ? selector?.hasAllOption
                : true,
            multiSelect:
              selector?.multiSelect !== undefined
                ? selector?.multiSelect
                : true,
            isLoading:
              analyticsApis.parentBrandsApi.isLoading ||
              !analyticsApis.parentBrandsApi.isExecuted ||
              loadingFilters.includes(SelectorNames.ParentBrand),
          };
        case SelectorNames.SubBrand:
          return {
            label: t("filters.subbrand"),
            name: SelectorNames.SubBrand,
            options: (analyticsApis.subBrandsApi?.data || []).map((brand) => ({
              value: brand.ID,
              label: brand.BRAND,
              key: brand.ID,
            })),
            handleChange: (event: EventType) =>
              handleFilterChange(
                selector?.dependentFilters || [SelectorNames.Products],
                event,
                SelectorNames.SubBrand
              ),
            labelColor: selector?.labelColor || "#1565C0",
            defaultValue: selector?.defaultValue,
            hasAllOption:
              selector?.hasAllOption !== undefined
                ? selector?.hasAllOption
                : true,
            multiSelect:
              selector?.multiSelect !== undefined
                ? selector?.multiSelect
                : true,
            isLoading:
              analyticsApis.subBrandsApi.isLoading ||
              !analyticsApis.subBrandsApi.isExecuted ||
              loadingFilters.includes(SelectorNames.SubBrand),
          };
        case SelectorNames.Label:
          return {
            label: t("filters.label"),
            name: SelectorNames.Label,
            options: (analyticsApis.labelsApi?.data || []).map((label) => ({
              value: label.ID,
              label: label.name,
              key: label.ID,
              color: label.color,
              access: label.access,
            })),
            handleChange: (event: EventType) =>
              handleFilterChange(
                selector?.dependentFilters || [],
                event,
                SelectorNames.Label
              ),
            defaultValue: selector?.defaultValue,
            hasAllOption:
              selector?.hasAllOption !== undefined
                ? selector?.hasAllOption
                : true,
            allValue: t('filters.labelAll') || 'None',
            multiSelect:
              selector?.multiSelect !== undefined
                ? selector?.multiSelect
                : false,
            isLoading:
              analyticsApis.labelsApi.isLoading ||
              !analyticsApis.labelsApi.isExecuted ||
              loadingFilters.includes(SelectorNames.Label),
          };
        case SelectorNames.Seller:
          return {
            label: t("filters.seller"),
            name: SelectorNames.Seller,
            options: (analyticsApis.sellerApi?.data || []).map((s, i) => ({
              value: s.SHOP_ID_EXT,
              label: s.SHOP_NAME || s.SHOP_ID_EXT.toString(),
              key: i,
            })),
            handleChange: (event: EventType) =>
              handleFilterChange(
                selector?.dependentFilters || [],
                event,
                SelectorNames.Seller
              ),
            defaultValue: selector?.defaultValue,
            hasAllOption:
              selector?.hasAllOption !== undefined
                ? selector?.hasAllOption
                : true,
            multiSelect:
              selector?.multiSelect !== undefined
                ? selector?.multiSelect
                : true,
            isLoading:
              analyticsApis.sellerApi.isLoading ||
              !analyticsApis.sellerApi.isExecuted ||
              loadingFilters.includes(SelectorNames.Seller),
          };
        case SelectorNames.Market:
          return {
            label: t("filters.market"),
            name: SelectorNames.Market,
            options: [
              {
                value: 0,
                label: "My Brands",
                key: 0,
              },
            ],
            disabled: selector.disabled,
            handleChange: (event: EventType) =>
              handleFilterChange(
                selector?.dependentFilters || [],
                event,
                SelectorNames.Market
              ),
            labelColor: selector?.labelColor || "#1565C0",
            defaultValue: selector?.defaultValue,
            hasAllOption:
              selector?.hasAllOption !== undefined
                ? selector?.hasAllOption
                : false,
            multiSelect:
              selector?.multiSelect !== undefined
                ? selector?.multiSelect
                : false,
            isLoading: loadingFilters.includes(SelectorNames.Market),
          };
        case SelectorNames.Availabilities:
          return {
            label: t("filters.availability"),
            name: SelectorNames.Availabilities,
            options: optionsAvailability,
            disabled: selector.disabled,
            handleChange: (event: EventType) =>
              handleFilterChange(
                selector?.dependentFilters || [],
                event,
                SelectorNames.Availabilities
              ),
            labelColor: selector.defaultValue
              ? selector.defaultValue
              : selector?.labelColor,
            defaultValue: selector?.defaultValue,
            hasAllOption:
              selector?.hasAllOption !== undefined
                ? selector?.hasAllOption
                : false,
            multiSelect:
              selector?.multiSelect !== undefined
                ? selector?.multiSelect
                : false,
            isLoading: loadingFilters.includes(SelectorNames.Availabilities),
          };
        default:
          return {
            label: selector.name,
            name: selector.name as SelectorNames,
            options: [],
          };
      }
    });
  }, [
    loadingFilters,
    manufacturersData,
    analyticsApis,
    handleManufacturerChange,
    selectorsConfig,
    t,
  ]);

  useEffect(() => {
    /**
     * If filters manually changed, await selector option changes.
     * If selected option no longer in option, change to default, except for `manufacturer`.
     */
    if (hasManuallyChanged.current) {
      hasManuallyChanged.current = false;

      const newSelectors = commonSelectorsConfig.reduce((acc, csc) => {
        if (csc.name === SelectorNames.Manufacturer) {
          return acc;
        }
        const oldValue: (string | number)[] | number =
          currentSelectors[csc.name as keyof AnalyticsRequestBody] || [];

        // should always be true. problem from_date, to_date is number but won't be used here
        const opt = Array.isArray(oldValue)
          ? csc.options
              .filter((o) => oldValue.includes(o.value))
              .map((o) => o.value)
          : csc.options
              .filter((o) => oldValue.toString() === o.value)
              .map((o) => o.value);

        return {
          ...acc,
          [csc.name]: opt,
        };
      }, {});
      const completeSelectors = { ...currentSelectors, ...newSelectors };

      setCurrentSelectors(completeSelectors);
      setLoadingFilters([]);
      fetchDependentAPIs(completeSelectors);
    }
  }, [commonSelectorsConfig, currentSelectors]);

  return {
    currentSelectors,
    setCurrentSelectors,
    commonSelectorsConfig,
    handleManufacturerChange,
  };
};

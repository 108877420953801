import React, {useEffect, useMemo, useState} from "react";
import {GridColDef} from "@mui/x-data-grid-pro";
import useData from "@/hooks/auth/useData";
import { Paper as MuiPaper } from "@mui/material";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { MuiGridTable } from "@/components/tables/DataGridTable";
import { useColumns } from "@/hooks/useColumnsData";
import ContentContainer from "@/components/contentContainer";
import { useTranslation } from "react-i18next";
import {ContractsResponse} from "@/api/management/types";
import {green, orange} from "@/hooks/useColumnsData/consts";
import {convertMonth} from "@/utils/monthConverter";
import CreateContractModal from "./CreateContractModal";
import { ManufacturersParams } from "@/api/useManufacturers";

const Paper = styled(MuiPaper)(spacing);

const ContractsOverview = (): JSX.Element => {
  const { t } = useTranslation();
  const { contractsApi, accountsApi, modulesApi, manufacturersApi, isLoading, adminsApi } = useData();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isManufacturerLoaded, setIsManufacturerLoaded] = useState(false);

  useEffect(() => {
    if (!contractsApi.data || contractsApi.data.length === 0) {
      contractsApi.execute(undefined, false);
    }
    if (!accountsApi.data || accountsApi.data.length === 0) {
      accountsApi.execute(undefined, false);
    }
    if (!modulesApi.data || modulesApi.data.length === 0) {
      modulesApi.execute(undefined, false);
    }
    if (!manufacturersApi?.allManufacturers.data || manufacturersApi?.allManufacturers.data.length === 0) {
      manufacturersApi?.allManufacturers.execute(undefined, false);
    }
    if (!adminsApi.data || adminsApi.data.length === 0) {
      adminsApi.execute(undefined, false);
    }

  }, [contractsApi, accountsApi, modulesApi, manufacturersApi, adminsApi]);

  const rows: ContractsResponse[] = useMemo(() => {
    return contractsApi?.data || [];
  }, [contractsApi?.data]);
  const allColumns = useColumns();

  const columns: GridColDef<ContractsResponse>[] = [
    {
      ...allColumns.rightAlignedColumn,
      field: "account",
      headerName: t("tableColumns.account") || "Account",
      width: 100,
      valueGetter: allColumns.createValueGetter("account", false, 0, false)
    },
    {
      ...allColumns.productColumn,
      flex: 1,
      valueGetter: allColumns.createValueGetter("product", false, 0, false)
    },
    {
      ...allColumns.centeredColumn,
      field: "quantity",
      headerName: t("tableColumns.quantity") || "Quantity",
      width: 100,
      valueGetter: allColumns.createValueGetter("quantity", false, 0, false)
    },
    {
      ...allColumns.centeredColumn,
      field: "term",
      headerName: t("tableColumns.term") || "Term",
      width: 100,
      valueFormatter: (params) => {
        if (!params) return '';
        return convertMonth(params, t)
      },
      valueGetter: allColumns.createValueGetter("term", false, 0, false)
    },
    {
      ...allColumns.centeredColumn,
      field: "notice",
      headerName: t("tableColumns.notice") || "Notice",
      width: 100,
      valueFormatter: (params) => {
        if (!params) return '';
        return convertMonth(params, t)
      },
      valueGetter: allColumns.createValueGetter("notice", false, 0, false)
    },
    {
      ...allColumns.centeredColumn,
      field: "cycle",
      headerName: t("tableColumns.cycle") || "Cycle",
      width: 100,
      valueFormatter: (params) => {
        if (!params) return '';
        return convertMonth(params, t)
      },
      valueGetter: allColumns.createValueGetter("cycle", false, 0, false)
    },
    {
      ...allColumns.createChipColumn((params) => params.row.status === 'active'? green : orange),
      field: "status",
      headerName: t("tableColumns.contractStatus") || "Status",
      width: 100,
      valueFormatter: (params) => {
        if (!params) return '';
        return t(`general.${params}`)
      },
      valueGetter: allColumns.createValueGetter("status", false, 0, false),
    },
    {
      ...allColumns.dateColumn,
      field: "start",
      width: 100,
      headerName: t("tableColumns.start") || "Start",
      valueGetter: allColumns.createValueGetter("start", false, 0, false)
    },
    /* Payment Column not implemented right now
    {
      ...allColumns.coloredDateColumn,
      field: "payment",
      width: 100,
      headerName: t("tableColumns.payment") || "Payment",
      valueGetter: allColumns.createValueGetter("payment", false, 0, false)
    },
    */
    {
      ...allColumns.dateColumn,
      field: "end",
      width: 100,
      headerName: t("tableColumns.end") || "end",
      valueGetter: allColumns.createValueGetter("end", false, 0, false)
    },
  ];

  const groupingColDef = {
    ...allColumns.idColumn,
    headerName: t("tableColumns.contractId") || "Contract ID",
    width: 100,
    valueGetter: allColumns.createValueGetter("contract_id", false, 0, false),
    hideDescendantCount: true,
  };

  const handleCreateClick = () => {
    if (!accountsApi.data || !modulesApi.data) {
      return;
    }
    setIsCreateModalOpen(true);
  };

  return (
    <ContentContainer>
      <Paper>
        <MuiGridTable
          rows={rows}
          columns={columns}
          loading={isLoading}
          getTreeDataPath={(row) => row.hierarchy || []}
          groupingColDef={groupingColDef}
          treeData={true}
          filterBarType={2}
          initialPageSize={10}
          hasControls={true}
          searchPlaceholder={
            t("tableColumns.contractSearchPlaceholder") || "Account, Contract, ..."
          }
          isError={contractsApi?.isError}
          error={contractsApi?.error}
          onCreateClick={handleCreateClick}
        />
      </Paper>
      <CreateContractModal
        open={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
        onSuccess={() => {
          contractsApi.execute();
        }}
        listApi={contractsApi}
        accounts={accountsApi.data || []}
        modules={modulesApi.data || []}
        manufacturers={manufacturersApi?.allManufacturers.data || []}
        accountExecutives={adminsApi?.data || []}
      />
    </ContentContainer>
  );
};

export default ContractsOverview;

import ColorPicker from "@/components/ColorPickerModal";
import useData from "@/hooks/auth/useData";
import { debounce } from "@/hooks/useDebaunce";
import { Box, Typography, Tooltip } from "@mui/material";
import { t } from "i18next";
import React, { useCallback, useState } from "react";
import { LabelColumnsProps } from "./types";
import { LabelsResponse } from "@/api/setup/types";

const LabelColumns = ({ params }: LabelColumnsProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { setupLabelsUpdateApi } = useData();
  const labelColor = params?.row?.color ? params?.row?.color : "";

  const oldKeywords = params.row.keywords.map((k: LabelsResponse) => k.ID);
  const oldCategories = params.row.categories.map((c: LabelsResponse) => c.ID);

  const debouncedUpdateColor = useCallback(
    debounce((newColor: string) => {
      setupLabelsUpdateApi.execute({
        id: params.row.id,
        color: newColor,
        keywords: oldKeywords,
        categories: oldCategories,
      });
    }, 500),
    []
  );

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleColorChange = (newColor: string) => {
    try {
      debouncedUpdateColor(newColor);
      params.row.color = newColor;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        sx={{ width: "100%", height: "100%" }}
      >
        <Tooltip title={t("pages.labels.setColor")} placement="top">
          <Box
            sx={{
              width: 14,
              height: 14,
              borderRadius: "50%",
              backgroundColor: labelColor,
              mr: "4px",
              cursor: "pointer",
            }}
            onClick={handleOpen}
          />
        </Tooltip>
        <Typography fontWeight={500}>{params.row.name}</Typography>
      </Box>
      <ColorPicker
        anchorEl={anchorEl}
        handleClose={handleClose}
        initialValue={labelColor}
        handleColorChange={handleColorChange}
      />
    </>
  );
};

export default LabelColumns;

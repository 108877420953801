import {
  ContentType,
  createMutationFunction,
} from "../mutations/createMutationFunction";
import { createQueryFunction } from "../queries/createQueryFunction";
import { useApiMutation } from "../useApiMutation";
import { useApiQuery } from "../useApiQuery";
import {
  MarketCreateRequest,
  MarketUpdateRequest,
  MarketsResponse,
  SetupParams,
} from "./types";

const key = "markets";

export const useMarkets = () =>
  useApiQuery<MarketsResponse[], SetupParams>({
    key: key,
    queryFn: createQueryFunction<MarketsResponse[], SetupParams>(
      "app/setup/get_markets"
    ),
  });

export const createMarket = () => {
  const labels = useApiMutation<MarketsResponse, MarketCreateRequest>({
    mutationFn: (variables, signal) => {
      return createMutationFunction<MarketsResponse, MarketCreateRequest>(
        "app/setup/markets",
        ContentType.JSON,
        "POST"
      )(variables, signal);
    },
  });

  return labels;
};

export const updateMarket = () => {
  const labels = useApiMutation<MarketsResponse, MarketUpdateRequest>({
    mutationFn: (variables, signal) => {
      const { id, ...body } = variables!;
      return createMutationFunction<MarketsResponse, MarketCreateRequest>(
        `app/setup/markets/${id}`,
        ContentType.JSON,
        "PUT"
      )(body, signal);
    },
  });

  return labels;
};

import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from "react";
import { TextField, PopperProps, Box } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Popper from "@mui/material/Popper";
import { CustomAutocompleteProps } from "./types";
import Checkbox from "@mui/material/Checkbox";
import { useTranslation } from "react-i18next";
import { Option } from "@/types/selectorOption";
import { arraysSameUnordered } from "@/utils/arraysSameUnordered";

const CustomAutocomplete: React.FC<CustomAutocompleteProps> = ({
  label,
  value,
  onChange,
  options,
  sorting = (a, b) => a.label.localeCompare(b.label),
  groupBy = () => "",
  disableClearable = false,
  disabled = false,
  labelColor,
  defaultValue = [],
  multiSelect = true,
  hasAllOption = true,
  allValue = "",
  additionalOptions = [],
  additionalSelectionOptions = (value) => value,
  isLoading = false,
  width,
  textFieldVariant = "outlined",
  textFieldShrink = false,
  error = false,
  helperText = "",
}) => {
  defaultValue = Array.isArray(defaultValue) ? defaultValue : [defaultValue];
  const minWidth = 110;
  const { t } = useTranslation();
  const allOpt = {
    value: "All",
    label: allValue || t("filters.all"),
    key: -1,
    property: 0,
  };

  const allOptions = useMemo(() => {
    const sortedOptions = options.sort(sorting);
    const combinedOptions = [...additionalOptions, ...sortedOptions];
    return hasAllOption ? [allOpt, ...combinedOptions] : combinedOptions;
  }, [options]);

  const optionFromDefault = useMemo(() => {
    return allOptions.filter((o) => defaultValue.includes(o.value));
  }, [allOptions]);

  const labelFromDefault = useMemo(() => {
    return optionFromDefault.map((o) => o.label).join(" + ") || "";
  }, [optionFromDefault]);

  //const hasColors = allOptions.find((o) => "color" in o);

  const autoRef = useRef<HTMLElement | undefined>(undefined);
  const inputRef = useRef<HTMLElement | null>(null);
  const triggerCloseRef = useRef(false);
  const [inputWidth, setInputWidth] = useState<number>(minWidth);
  const spanRef = useRef<HTMLSpanElement | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [intermediateOpen, setIntermediateOpen] = useState<boolean>(false);

  const [inputValue, setInputValue] = useState<string>(
    multiSelect
      ? value.length > 0
        ? value.map((v) => v.label).join(" + ")
        : hasAllOption
        ? allOpt.label
        : labelFromDefault
      : value[0]?.label || (hasAllOption ? allOpt.label : labelFromDefault)
  );

  // parentValue needs to be memorized otherwise issues with searching params
  const parentValue = useMemo(() => {
    return multiSelect
      ? value.length > 0
        ? value
        : hasAllOption
        ? [allOpt]
        : undefined
      : value[0] || (hasAllOption ? allOpt : null);
  }, [value]);

  const [intermediateValue, setIntermediateValue] = useState<
    | Option
    | Option[]
    | { value: string; label: string; key: number }[]
    | undefined
  >(parentValue);

  useEffect(() => {
    if (intermediateOpen || isOpen) {
      setInputValue("");
    } else {
      setIntermediateValue(parentValue);
      setInputValue(
        multiSelect
          ? value.length > 0
            ? value.map((v) => v.label).join(" + ")
            : hasAllOption
            ? allOpt.label
            : labelFromDefault
          : value[0]?.label || (hasAllOption ? allOpt.label : labelFromDefault)
      );
    }
  }, [parentValue]);

  useEffect(() => {
    // focus the input ref. important for switching between filters (if the first triggers loading on the second)
    if (!isLoading && isOpen) {
      inputRef.current?.focus();
    }
  }, [isLoading, isOpen]);

  useEffect(() => {
    if (spanRef.current) {
      setInputWidth(
        spanRef.current.offsetWidth + 10 < minWidth
          ? minWidth
          : spanRef.current.offsetWidth + 10
      );
    }
  }, [inputValue]);

  const handleChange = (
    _: React.SyntheticEvent<Element, Event>,
    value: Option | Option[] | null
  ) => {
    if (isOpen) {
      triggerCloseRef.current = true;
    }

    if (value === null || (Array.isArray(value) && value.length === 0)) {
      if (!isOpen) {
        setInputValue(hasAllOption ? allOpt.label : labelFromDefault);
      }
      if (!multiSelect || (multiSelect && !isOpen)) {
        onChange(hasAllOption ? [] : optionFromDefault);
      }
      setIntermediateValue(
        hasAllOption
          ? multiSelect
            ? [allOpt]
            : allOpt
          : multiSelect
          ? optionFromDefault
          : optionFromDefault[0]
      );
      return;
    }

    if (Array.isArray(value)) {
      if (hasAllOption) {
        const wasOnlyAllSelected =
          Array.isArray(intermediateValue) &&
          intermediateValue.some((iv) => iv.key === -1) &&
          intermediateValue.length === 1;
        value = wasOnlyAllSelected
          ? value.filter((v) => v.key !== -1)
          : value.some((v) => v.key === -1)
          ? [allOpt]
          : value;
      }
      value = additionalSelectionOptions(value, options);
      setIntermediateValue(value);
    } else {
      value = hasAllOption && value?.key === -1 ? [allOpt] : [value];
      setInputValue(
        value[0]?.label || (hasAllOption ? allOpt.label : labelFromDefault)
      );

      const changeValue = hasAllOption
        ? value.filter((v) => v.key !== -1)
        : value;
      setIntermediateValue(value[0]);
      onChange(changeValue);
    }
  };

  const getOptionColor = (optionValue: string) => {
    const matchedOption = options.find((opt) => opt.value === optionValue);
    return matchedOption?.color || "gray";
  };

  const handleInputChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: string
  ) => {
    if (event && isOpen) {
      if (event.type === "keydown" && value === "") {
        return;
      }
      setInputValue(value);
    }
  };

  const handleOpen = () => {
    setInputValue("");
    setIntermediateOpen(true);
    // important: otherwise the popper will not be correctly positioned due to autocompletes are growing
    setTimeout(() => {
      setIsOpen(true);
    }, 100);
  };

  const handleClose = () => {
    setIsOpen(false);
    setIntermediateOpen(false);
    if (Array.isArray(intermediateValue)) {
      setInputValue(
        intermediateValue.length > 0
          ? intermediateValue.map((v) => v.label).join(" + ")
          : hasAllOption
          ? allOpt.label
          : labelFromDefault
      );
      if (triggerCloseRef.current) {
        onChange(intermediateValue.filter((v) => v.key !== -1));
      }
    } else if (!triggerCloseRef.current) {
      setInputValue(
        intermediateValue?.label ||
          (hasAllOption ? allOpt.label : labelFromDefault)
      );
    }
    autoRef.current?.blur();
    triggerCloseRef.current = false;
  };

  // important: otherwise scrolling on multiselect does not work properly
  const CustomPopper = useCallback(
    (props: PopperProps) => (
      <Popper
        {...props}
        style={{ width: "fit-content" }}
        placement="bottom-start"
      />
    ),
    []
  );

  return (
    <Autocomplete
      ref={autoRef}
      loading={isLoading}
      loadingText={t("filters.loading")}
      disableCloseOnSelect={multiSelect}
      disableClearable={
        disableClearable ||
        (Array.isArray(intermediateValue)
          ? intermediateValue[0]?.key === -1 ||
            arraysSameUnordered(
              intermediateValue.map((v) => v.value),
              defaultValue
            )
          : intermediateValue?.key === -1 ||
            intermediateValue?.value === defaultValue[0])
      }
      groupBy={groupBy}
      multiple={multiSelect}
      value={Array.isArray(intermediateValue) ? intermediateValue : []}
      inputValue={isLoading ? t("filters.loading") || "Loading..." : inputValue}
      disabled={isLoading || disabled}
      onChange={(event, value) => handleChange(event, value)}
      options={allOptions}
      open={isOpen}
      onOpen={handleOpen}
      onClose={handleClose}
      getOptionLabel={(option) => option?.label || ""}
      isOptionEqualToValue={(option, value) => option?.value == value?.value}
      onInputChange={handleInputChange}
      renderInput={(params) => (
        <Box position={"relative"}>
          <span
            ref={spanRef}
            style={{
              position: "absolute",
              visibility: "hidden",
              whiteSpace: "pre",
              paddingRight: "50px",
              maxWidth: "200px",
            }}
          >
            {inputValue}
          </span>
          <TextField
            {...params}
            inputRef={inputRef}
            error={error}
            helperText={error ? helperText : ""}
            label={label}
            variant={textFieldVariant}
            placeholder={t("tableColumns.search") || ""}
            InputProps={{
              ...params.InputProps,
              // for now null. later we can put colors with in it
              startAdornment: null,
            }}
            InputLabelProps={textFieldShrink ? { shrink: true } : {}}
            sx={{
              width: `${width ? width : inputWidth}px`,
              maxWidth: "200px",
              display: "flex",
              "& .MuiInputBase-root": {
                height: "40px",
              },
              "& .MuiInputLabel-root": {
                top: "3px",
                color: labelColor,
                transition: "none",
              },

              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: value.length > 0 ? "#757575" : undefined,
              },
            }}
          />
        </Box>
      )}
      renderOption={(props, option, { selected, index }) => (
        <li
          {...props}
          key={`${option?.label || ""}-${index}`}
          style={
            multiSelect
              ? { paddingLeft: 0, paddingTop: 0, paddingBottom: 0 }
              : {}
          }
        >
          {multiSelect && (
            <Checkbox checked={selected} sx={{ padding: "4px" }} />
          )}
          {"color" in option && (
            <span
              style={{
                width: 10,
                height: 10,
                borderRadius: "50%",
                backgroundColor: getOptionColor(option?.value || ""),
                marginRight: 4,
              }}
            />
          )}
          {option?.label || ""}
        </li>
      )}
      size="small"
      sx={{
        maxWidth: "200px",
        width: `${inputWidth}px`,
        "& .MuiAutocomplete-root": {
          maxWidth: "200px !important",
          minWidth: `${minWidth}px !important`,
        },
        "& .MuiInputBase-input": {
          paddingLeft: "2px !important",
        },
        "& .MuiSvgIcon-root": {
          width: "16px",
          height: "16px",
        },
        "&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiOutlinedInput-root":
          {
            paddingRight: "40px !important",
          },
      }}
      PopperComponent={CustomPopper}
    />
  );
};

export default CustomAutocomplete;

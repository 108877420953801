import { useEffect, useMemo, useState } from "react";
import {
  Alert,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import useData from "@/hooks/auth/useData";
import DynamicDialog from "@/components/DynamicDialog";
import { PropertiesResponse } from "@/api/buynow/types";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Manufacturer } from "@/api/useManufacturers";

export default function CreateProperty({
  dialogOpen,
  setDialogOpen,
  onSuccess,
}: {
  dialogOpen: boolean;
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSuccess: (newProperty: PropertiesResponse) => void;
}) {
  const { t } = useTranslation();
  const { selectedManufacturers, manufacturersApi, createPropertyApi } = useData();
  const [isLoading, setIsLoading] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const [urlErrors, setUrlErrors] = useState({ url: false });
  const [selectedManufacturer, setSelectedManufacturer] = useState<any>(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const theme = useTheme();
  const manufacturersData = useMemo(() => manufacturersApi?.manufacturers.data || [], [manufacturersApi?.manufacturers]);

  useEffect(() => {
    if (selectedManufacturers.length === 1) {
      const manufacturer = manufacturersData.find(
        (manufacturer: Manufacturer) => manufacturer.ID === selectedManufacturers[0].ID
      );
      if (manufacturer?.access === 'write') {
        setSelectedManufacturer(manufacturer);
        handleFormChange("manufacturer", manufacturer.ID);
      }
    }
  }, [selectedManufacturers, manufacturersData, dialogOpen]);
  
  const initialFormState = {
    type: 0,
    manufacturer: "",
    url: "",
    expirationDate: "",
    domain: "",
  };

  const [formState, setFormState] = useState(initialFormState);

  const componentsTypes = [
    { value: 0, label: t("pages.buyNowComponent.typeContent") },
    { value: 1, label: t("pages.buyNowComponent.typePerformance") },
  ];

  const isValidUrl = (url: string): boolean => {
    const urlRegex = /^https?:\/\/([a-zA-Z0-9-]{1,63}\.)+[a-zA-Z]{2,}$/;
    return urlRegex.test(url);
  };  

  const validateForm = () => {
    const { manufacturer, url, expirationDate, type } = formState;
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const selectedDate = new Date(expirationDate);
    const isFutureDate = expirationDate && selectedDate.getTime() >= today.getTime();
  
    return manufacturer && expirationDate && isFutureDate && (type === 1 || isValidUrl(url));
  };
  

  useEffect(() => {
    setIsBtnDisabled(!validateForm());
    setUrlErrors({
      url: formState.url !== "" && formState.type === 0 && !isValidUrl(formState.url),
    });
  }, [formState]);

  const handleDialogClick = async () => {
    setIsLoading(true);
    try {
      const createdProperty = await createPropertyApi?.execute({
        manufacturer: selectedManufacturer?.ID,
        domain: formState?.url.replace(/^(https?:\/\/)/, ''),
        url: formState?.url,
        expires: formState?.expirationDate,
        type: formState?.type
      });
  
      if (selectedManufacturers.some(manufacturer => manufacturer.ID === createdProperty.manufacturer)) {
        onSuccess(createdProperty);
      }
    } catch (error) {
      console.error(t("pages.buyNowComponent.operationFailed"), error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const handleFormChange = (key: string, value: any) => {
    setFormState((prevState) => ({
      ...prevState,
      [key]: value,
      ...((key === "type" && value === 1) ? { url: "bn.maven360.io" } : (key === "type") ? { url: ""} : {}),
    }));
  };
  
  const handleResetState = () => {
    setFormState(initialFormState);
    setIsBtnDisabled(true);
    setUrlErrors({ url: false });
  };

  const handleManufacturerChange = (value: string | number) => {
    const manufacturer = (manufacturersData as Array<Manufacturer>)?.find((manufacturer: Manufacturer) => manufacturer.ID === value);
    setSelectedManufacturer(manufacturer);
    handleFormChange("manufacturer", value);
  };

  const scriptContent = `
  <script>
    (function(){
      var bnScript = document.createElement('script');
      bnScript.async = true;
      bnScript.src = 'https://connect.maven360.io/buyButton.js?v=' + (Date.now() - Date.now() % 43200000);
      document.head.appendChild(bnScript);
    })();
  </script>`.trim();

  const handleCopy = () => {
    navigator.clipboard.writeText(scriptContent).then(() => {
      setOpenSnackbar(true);
    }).catch((err) => {
      console.error('Failed to copy: ', err);
    });
  };
 
  const formContent = (
    <Box>
      <Typography sx={{ padding: "16px 0" }}>{t("pages.buyNowComponent.startCreatingExperience")}</Typography>
      <Box  sx={{ paddingBottom: 1 }}>
        <FormControl fullWidth margin="dense">
          <InputLabel>{t("pages.buyNowComponent.typeLabel")}</InputLabel>
          <Select
            label={t("pages.buyNowComponent.typeLabel")}
            value={formState.type}
            onChange={(e) => handleFormChange("type", e.target.value)}
          >
            {componentsTypes.map((prop) => (
              <MenuItem key={prop.value} value={prop.value}>
                {prop.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <FormControl fullWidth margin="dense" sx={{ paddingBottom: 1 }}>
        <InputLabel shrink>{t("pages.buyNowComponent.manufacturer")}</InputLabel>
          <Select
            label={t("pages.buyNowComponent.manufacturer")}
            value={formState.manufacturer || ""}
            onChange={(e) => handleManufacturerChange(e.target.value)}
            displayEmpty
            sx={{
              '& .MuiSelect-select': {
                color: formState.manufacturer ? 'inherit' : 'text.disabled', // Example customization
              },
            }}
          >
            <MenuItem value="" disabled 
              sx={{
                '& .MuiSelect-select': {
                  color: theme.palette.text.disabled,
                },
              }}
            >
              {t("pages.buyNowComponent.manufacturerPlaceholder")}
            </MenuItem>
            {!manufacturersData ? <MenuItem>Loading...</MenuItem> : (manufacturersData as Array<Manufacturer>)?.map((manufacturer) => (
              <MenuItem key={manufacturer.ID} value={manufacturer.ID} disabled={manufacturer.access != "write"}>
                {manufacturer.NAME}
              </MenuItem>
            ))}
          </Select>
      </FormControl>
      <TextField
        sx={{ paddingBottom: 1 }}
        label={t("pages.buyNowComponent.propertyLabel") + ' URL'}
        fullWidth
        margin="dense"
        value={formState.url}
        onChange={(e) => handleFormChange("url", e.target.value)}
        placeholder={t("pages.buyNowComponent.createPropertyPlaceholder") || "Add URL of your content page"}
        error={urlErrors.url}
        helperText={urlErrors.url ? t("pages.buyNowComponent.invalidPropertyFormat") : ""}
        InputLabelProps={{ shrink: true }}
        disabled={formState.type === 1}
      />
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        sx={{ width: '34%' }}
        label={t('pages.buyNowComponent.expirationDate')}
        value={formState.expirationDate ? dayjs(formState.expirationDate).format('YYYY-MM-DD') : null}
        onChange={(newValue) => {
          const adjustedValue = newValue ? dayjs(newValue).format('YYYY-MM-DD') : null;
          handleFormChange('expirationDate', adjustedValue);
        }}
        slots={{
          openPickerIcon: CalendarTodayIcon,
          textField: TextField,
        }}
        slotProps={{
          textField: {
            fullWidth: true,
            margin: 'dense',
            error: Boolean(
              formState.expirationDate &&
              dayjs(formState.expirationDate).isBefore(dayjs().format('YYYY-MM-DD'))
            ),
            helperText:
              formState.expirationDate &&
              dayjs(formState.expirationDate).isBefore(dayjs().format('YYYY-MM-DD'))
                ? t('pages.buyNowComponent.invalidExpirationDate')
                : '',
            InputLabelProps: { shrink: true },
          },
          openPickerButton: {
            sx: {
              '& .MuiSvgIcon-root': {
                color: theme.palette.text.primary,
                fontSize: '1.5rem',
              },
            },
          },
        }}
      />
    </LocalizationProvider>
    <Snackbar
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          {"Script copied successfull"}
        </Alert>
      </Snackbar>
    </Box>
  );

  const onClose = () => {
    handleResetState();
    setDialogOpen(false);
  };

  return (
    <DynamicDialog
      open={dialogOpen}
      onClose={onClose}
      onClick={handleDialogClick}
      isLoading={isLoading}
      closeOnBackdropClick={true}
      title={t("pages.buyNowComponent.createPropertyTitle")}
      content={formContent}
      primaryButtonText={t("pages.buyNowComponent.createButtonText") as string}
      isPrimaryBtnDisabled={isBtnDisabled}
      showSuccessOutside={true}
      footer={ 
        formState.type === 0 ?
        <Box sx={{ position: 'relative' }}>
          <TextField
            label={t('pages.buyNowComponent.integrationCode')}
            value={scriptContent}
            multiline
            fullWidth
            disabled
            margin="dense"
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
          />
          <Button
            variant="text"
            sx={{
              minWidth: 'auto',
              position: 'absolute',
              bottom: 10,
              right: 10
            }}
            onClick={handleCopy}
          ><ContentCopyIcon sx={{ color: theme.palette.text.primary}} /></Button>
        </Box> : undefined
      }
    />
  );
}

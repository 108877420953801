import { createQueryFunction } from "../queries/createQueryFunction";
import { useApiQuery } from "../useApiQuery";
import { DefJsonResponse } from "./types";

const basePath = "app/buy-now/default_config.json?is_social=0"

export const useDefContentJson = () => {
  const defContentJsonApi = useApiQuery<DefJsonResponse>({
    key: "content-json",
    queryFn: createQueryFunction(basePath),
  });
  return defContentJsonApi;
};
import { useApiQuery } from "../useApiQuery";
import { createQueryFunction } from "../queries/createQueryFunction";
import { ModulesResponse, ModulesApiType, AccountsApiType } from "./types";


export const useModules = (): ModulesApiType => {
    return useApiQuery<ModulesResponse[], {}>({
      key: 'modules',
      queryFn: createQueryFunction(`app/management/modules`),
    });
};
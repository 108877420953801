import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  InputBase,
  Modal,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { SearchIcon, Check } from "lucide-react";
import { useNavigate } from "react-router-dom";
import useData from "@/hooks/auth/useData";
import { LabelsResponse } from "@/api/setup/types";
import { AddLabelsModalProps } from "./types";

export default function AddLabelsModal({
  anchorEl,
  handleClose,
  selectedLabel,
  keywordsId,
  setSelectedLabel,
  setupLabelsApi,
  isKeyword
}: AddLabelsModalProps) {
  const { t } = useTranslation();
  const [position, setPosition] = useState<{
    top: number;
    left: number;
  } | null>(null);

  const navigate = useNavigate();

  const [inputValue, setInputValue] = useState("");
  const [loadingLabelId, setLoadingLabelId] = useState<number | null>(null);
  const open = Boolean(anchorEl);
  const { setupLabelsUpdateApi, user } = useData();

  useEffect(() => {
    if (anchorEl) {
      const rect = anchorEl.getBoundingClientRect();
      const modalHeight = 375;
      const viewportHeight = window.innerHeight;

      const spaceBelow = viewportHeight - rect.bottom;
      const spaceAbove = rect.top;

      let topPosition;

      if (spaceBelow >= modalHeight) {
        topPosition = rect.bottom;
      } else if (spaceAbove >= modalHeight) {
        topPosition = rect.top - modalHeight;
      } else {
        topPosition = rect.top + (rect.height - modalHeight) / 2;
      }

      setPosition({
        top: topPosition,
        left: rect.left,
      });
    }
  }, [anchorEl]);
  const hasAccess = (label: LabelsResponse) => {
    return (
      (user?.account_id !== undefined &&
        user?.account_id !== null &&
        (label.access as { [key: number]: number })?.[user.account_id] === 1) ||
      user?.account_id === label?.owner.account_id
    );
  };

  const filteredLabels = (setupLabelsApi.data || [])
    ?.filter(
      (label) =>
        label.name.toLowerCase().includes(inputValue.toLowerCase()) ||
        label.comment.toLowerCase().includes(inputValue.toLowerCase())
    )
    .sort((a, b) => {
      const accessA = hasAccess(a);
      const accessB = hasAccess(b);
      if (accessA !== accessB) return accessA ? -1 : 1;

      return a.name.localeCompare(b.name);
    });

    const updateLabels = async (label: LabelsResponse) => {
      if (!hasAccess(label)) return;
    
      setLoadingLabelId(label.ID);
    
      const updatedKeywords = Array.isArray(label.keywords)
        ? label.keywords.some((item) => item.ID === keywordsId)
          ? label.keywords.filter((item) => item.ID !== keywordsId).map((item) => item.ID)
          : [...label.keywords, { ID: keywordsId }].map((item) => item.ID)
        : [];
    
      const updatedCategories = Array.isArray(label.categories)
        ? label.categories.some((item) => item.ID === keywordsId)
          ? label.categories.filter((item) => item.ID !== keywordsId).map((item) => item.ID)
          : [...label.categories, { ID: keywordsId }].map((item) => item.ID)
        : [];
        
    
      const payload = {
        id: label.ID,
        keywords: isKeyword ? updatedKeywords : label.keywords.map((item) => item.ID),
        categories: !isKeyword ? updatedCategories : label.categories.map((item) => item.ID),
      };
    
      try {
        await setupLabelsUpdateApi.execute(payload);
    
        await setupLabelsApi.execute(undefined, false);
    
        if (selectedLabel) {
          const isLabelSelected = selectedLabel.some(
            (selected) => selected.label_id === label.ID
          );
    
          setSelectedLabel(
            isLabelSelected
              ? selectedLabel.filter((selected) => selected.label_id !== label.ID)
              : [
                  ...selectedLabel,
                  {
                    label_id: label.ID,
                    label_name: label.name,
                    label_color: label.color,
                  },
                ]
          );
        }
      } catch (error) {
        console.error("Error updating labels:", error);
      } finally {
        setLoadingLabelId(null);
      }
    };
    
    

  const isSelected = (labelId: number) =>
    selectedLabel?.some((selected) => selected.label_id === labelId);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      BackdropProps={{
        style: {
          backgroundColor: "rgba(0, 0, 0, 0.25)",
        },
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: position?.top || 0,
          left: position?.left || 0,
          transform: "translateY(10px)",
          bgcolor: "background.paper",
          visibility: position ? "visible" : "hidden",
          boxShadow: 24,
          borderRadius: 2,
          padding: 2,
          minWidth: 320,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            borderBottom: "1px solid #ccc",
            padding: "5px",
            width: "100%",
          }}
        >
          <SearchIcon width={24} height={24} opacity={0.25} />
          <InputBase
            placeholder="Search..."
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            sx={{
              flex: 1,
              color: "#888",
              fontSize: "16px",
              "::placeholder": {
                color: "#aaa",
              },
              marginLeft: "5px",
            }}
          />
        </Box>

        <Box
          sx={{
            height: 280,
            overflow: "auto",
          }}
        >
          {filteredLabels.map((label, index) => {
            const access = hasAccess(label);
            return (
              <Box
                key={index}
                sx={{
                  padding: "7px 7px 7px 50px",

                  cursor: setupLabelsUpdateApi.isLoading
                    ? "not-allowed"
                    : access
                    ? "pointer"
                    : "default",
                  maxWidth: 320,
                  opacity: access ? 1 : 0.5,
                  ":hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                  },
                }}
                display="flex"
                alignItems="left"
                flexDirection="column"
                onClick={() =>
                  access &&
                  !setupLabelsUpdateApi.isLoading &&
                  updateLabels(label)
                }
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  {isSelected(label.ID) && loadingLabelId !== label.ID && (
                    <Box
                      sx={{
                        position: "absolute",
                        left: -35,
                        top: "5px",
                      }}
                    >
                      <Check width={16} height={16} />
                    </Box>
                  )}
                  {loadingLabelId === label.ID && (
                    <Box
                      sx={{
                        position: "absolute",
                        left: -35,
                        top: "5px",
                      }}
                    >
                      <CircularProgress size={16} color="inherit" />
                    </Box>
                  )}
                  <Box
                    sx={{
                      width: 14,
                      height: 14,
                      borderRadius: "50%",
                      backgroundColor: label.color,
                      mr: "8px",
                    }}
                  />
                  <Typography
                    fontWeight={700}
                    fontSize={"14px"}
                    lineHeight={"24px"}
                    color={access ? "text.primary" : "text.disabled"}
                  >
                    {label.name}
                  </Typography>
                </Box>
                <Typography
                  fontSize={"12px"}
                  color={access ? "text.secondary" : "text.disabled"}
                  lineHeight={"20px"}
                  mt={0.5}
                >
                  {label.comment}
                </Typography>
              </Box>
            );
          })}
        </Box>

        <Divider />
        <Button onClick={() => navigate("/setup/labels")}>
          + {t("pages.labels.addLabel")}
        </Button>
      </Box>
    </Modal>
  );
}

import { createMutationFunction, ContentType } from "./mutations/createMutationFunction";
import { ApiMutation, useApiMutation } from "./useApiMutation";

export interface TrafficData {
  page_title: string;
  module_id?: number | null;
  url: string;
  screen_size: string;
}

export interface UserTrafficResponse {
  message: string;
}

export type UserTrafficApiType = ApiMutation<
  UserTrafficResponse,
  TrafficData
>;

export const useUserTraffic = () => {
  return useApiMutation<UserTrafficResponse, TrafficData>({
    mutationFn: createMutationFunction<UserTrafficResponse, TrafficData>(
      "app/accounts/users/traffic",
      ContentType.JSON,
      "POST"
    ),
  });
};

import useData from "@/hooks/auth/useData";

export function formatUserNumber(
  value: number | string | Date,
  maximumFractionDigits?: number,
  minimumFractionDigits?: number,
  showYear: boolean = true,
  numFormat: string | undefined = undefined,
) {
  let numberFormat = "en-US";
  if(numFormat) {
    numberFormat = numFormat;
  } else {
    // can not be used inside hooks or if data context not available (etc. callbacks for graphs)
    // TODO Remove that. Conditionally having hooks is bad.
    const { user } = useData();
    numberFormat = user?.settings?.numberFormat ?? "en-US";
  }

  if (typeof value === "number") {
    return new Intl.NumberFormat(numberFormat, {
      useGrouping: true,
      minimumFractionDigits: minimumFractionDigits || 0,
      maximumFractionDigits: maximumFractionDigits || 2,
    }).format(value);
  }

  if (value instanceof Date) {
    return new Intl.DateTimeFormat(numberFormat, {
      day: "numeric",
      month: "numeric",
      year: showYear ? "numeric" : undefined,
    }).format(value);
  }

  return value;
}


export function formatShortDate(
  value: number | string | Date,
  showYear: boolean = true,
  numFormat: string | undefined = undefined,
  showTime: boolean = false,
) {
  // Determine the number format, defaulting to "en-US"
  let numberFormat = numFormat || "en-US";

  // Parse the input value into a Date object
  let date: Date;
  if (value instanceof Date) {
    date = value;
  } else if (typeof value === "string" || typeof value === "number") {
    date = new Date(value);
  } else {
    return value; // Return the value as-is if it's not a valid date type
  }

  // Configure the date format options
  const dateOptions: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "2-digit",
    year: showYear ? "2-digit" : undefined,
  };

  // If showTime is true, add hour and minute options
  if (showTime) {
    dateOptions.hour = "2-digit";
    dateOptions.minute = "2-digit";
    dateOptions.hour12 = false; // Use 24-hour format
  }

  // Format the date using Intl.DateTimeFormat
  const formattedDate = new Intl.DateTimeFormat(numberFormat, dateOptions).format(date);

  return formattedDate;
}

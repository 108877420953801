import React from "react";
import {Navigate, Outlet} from "react-router-dom";
import { Routes } from "@/consts/routes";
import useData from "@/hooks/auth/useData";
import {PreloaderBox} from "@/components/preloader-box/PreloaderBox";

interface RightsGuardProps {
  requiredRight?: string;
  noPermissionPath?: string;
}

function RightsGuard({
  requiredRight = "admin",
  noPermissionPath = Routes.noPermisssion,
}: RightsGuardProps) {
  const { user } = useData();

  if (!user) {
    return <PreloaderBox />;
  }

  // Redirect to the 403 page if the user's role doesn't match the required role
  if (user?.role !== requiredRight) {
    return <Navigate to={noPermissionPath} />;
  }

  return <Outlet />;
}

export default RightsGuard;
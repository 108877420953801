import { SetupApiType } from "./types";
import { useKeywords } from "./useKeywords";
import { createLabel, deleteLabel, updateLabel, useLabels } from "./useLabels";
import { createMarket, updateMarket, useMarkets } from "./useMarkets";
import { useSetupProduct as useProducts } from "./useProducts";
import { useCategories } from "@/api/setup/useCategories";

const useSetup = (): SetupApiType => {
  const setupLabelsApi = useLabels();
  const setupKeywordsApi = useKeywords();
  const setupCategoriesApi = useCategories();
  const setupMarketsApi = useMarkets();
  const setupProductsApi = useProducts();
  const setupLabelsUpdateApi = updateLabel();
  const setupLabelsCreateApi = createLabel();
  const setupLabelsDeleteApi = deleteLabel();
  const setupMarketsCreateApi = createMarket();
  const setupMarketUpdateApi = updateMarket();
  return {
    setupLabelsApi,
    setupKeywordsApi,
    setupCategoriesApi,
    setupMarketsApi,
    setupProductsApi,
    setupLabelsUpdateApi,
    setupLabelsCreateApi,
    setupLabelsDeleteApi,
    setupMarketsCreateApi,
    setupMarketUpdateApi,
  };
};

export default useSetup;

import { ManagementApiType } from "./types";
import {useContracts, useCreateContract} from "@/api/management/useContracts";
import {useAccounts} from "@/api/management/useAccounts";
import {useModules} from "@/api/management/useModules";
import { useAdmin } from "./useAdmins";

const useManagement = (): ManagementApiType => {
    const contractsApi = useContracts();
    const accountsApi = useAccounts();
    const modulesApi = useModules();
    const createContractApi = useCreateContract();
    const adminsApi = useAdmin();

    return {
        contractsApi,
        createContractApi,
        accountsApi,
        modulesApi,
        adminsApi
    }
};

export default useManagement;
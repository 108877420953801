import { useTranslation } from "react-i18next";
import { Box, Link, Chip, useTheme, Tooltip } from "@mui/material";
import { ColorSpan } from "./types";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import { ReactNode } from "react";
import {black, green, red} from "@/hooks/useColumnsData/consts";

const useCustomTableUtils = () => {
  const { t } = useTranslation();

  const getTranslatedHeaderName = (translationKey: string, fallback: string) => {
    return t(translationKey) as string || fallback;
  };

  const na = getTranslatedHeaderName('tableColumns.notAvailable', 'n.a.');

  const statusTranslations = {
    'active': getTranslatedHeaderName('tableColumns.active', 'Active'),
    'discontinued': getTranslatedHeaderName('tableColumns.discontinued', 'Discontinued'),
    'deleted': getTranslatedHeaderName('tableColumns.deleted', 'deleted')
  };
  
  const removeProtocol = (url: string | null) => {
    if (!url) {
      return null;
    }
    return url.replace(/^(https?:\/\/)/, '');
  }

  const avTranslations = {
    1: getTranslatedHeaderName('tableColumns.inStock', 'in stock'),
    2: getTranslatedHeaderName('tableColumns.limited', 'limited availability'),
    3: getTranslatedHeaderName('tableColumns.outOfStock', 'out of stock'),
    4: getTranslatedHeaderName('tableColumns.outOfTrade', 'out of trade'),
    5: getTranslatedHeaderName('tableColumns.unknown', 'unknown'),
    6: getTranslatedHeaderName('tableColumns.noBuyBox', 'No buy box'),
  };


  const yesNoTranslations = {
    'Yes': getTranslatedHeaderName('tableColumns.yes', 'Yes'),
    'No': getTranslatedHeaderName('tableColumns.no', 'No')
  };

  const renderTranslatedCell = (
    rawValue: string | number,
    translationMap: Record<string | number, string>,
    colorMap: string[],
    fallbackColorIndex: number = -1
  ) => {
    const translatedValue = translationMap[rawValue] || rawValue;
    
    const colorIndex = Object.keys(translationMap).includes(String(rawValue))
      ? Object.keys(translationMap).indexOf(String(rawValue))
      : fallbackColorIndex;
  
    return renderFixedColor(translatedValue, colorIndex, colorMap);
  };
  
  const trimUrl = (url: string) => {
    try {
      const parsedUrl = new URL(url); 
      let hostname = parsedUrl.hostname;
  
      if (hostname.startsWith('www.')) {
        hostname = hostname.slice(4);
      }
      
      return hostname;
    } catch {
      return url;
    }
  };
  
  const boolIntToString = (val: number, translate: boolean = true) => {
    if (val === 1) {
      return translate ? yesNoTranslations['Yes'] : 'Yes';
    } else if (val === 0) {
      return translate ? yesNoTranslations['No'] : 'No';
    }
    return na;
  };
  
  const replaceZeroByNone = (val: number) => {
    return val !== 0 ? val : getTranslatedHeaderName('tableColumns.none', 'None');
  };
  
  const renderLinkCell = (url: string | null, label?: string | null, hint: string = "") => (
    url ? (
      <Tooltip title={hint} placement="top">
        <Link href={url} target='_blank' underline='hover' fontWeight={700} color={"#2196F3"}>
          {label || url}
        </Link>
      </Tooltip>
    ) : label || na
  );
  
  const renderFixedColor = (value: string | number, colorIndex: number | undefined, colors: string[]) => {
    if (colorIndex !== -1 && colorIndex !== undefined) {
      return (
        <span style={{ color: colors[colorIndex] }}>
          {value}
        </span>
      );
    }
    return value;
  };
  
  const formatDate = (field: string) => {
    return (_: any, row: Record<string, any>) => {
      const val = row[field];
      if(val) {
        try {
          const date = new Date(val);
          const day = date.getDate().toString().padStart(2, '0');
          const month = (date.getMonth() + 1).toString().padStart(2, '0');
          const year = date.getFullYear().toString().slice(-2);
          const hours = date.getHours().toString().padStart(2, '0');
          const minutes = date.getMinutes().toString().padStart(2, '0');
          
          return `${day}.${month}.${year} ${hours}:${minutes}`;
        } catch {
          return na;
        }
        
      }
      return na;
    }
  };
  
  
 const parseCustomDate = (value: string, separator: string[] = [' ', '.', ':']): Date => {
    if (!value) return new Date(0);
  
    const [datePart, timePart] = value.split(separator[0]);
    const [year, month, day] = datePart.split(separator[1]);
    const [hours, minutes] = timePart?.split(separator[2]) || ['00', '00'];
  
    const fullYear = year?.length === 2 ? `20${year}` : year;
  
    const parsedDate = new Date(`${fullYear}-${month}-${day}T${hours}:${minutes}:00`);
    return parsedDate;
  };

  const renderDate = (value: string, userNumberFormat: string, colored: boolean = false) => {
      if (!value) {
        return value
      }
      const parsedDate = parseCustomDate(value, ["T", "-", ":"]);
      const result = formatValue(parsedDate, userNumberFormat, undefined, undefined, true, false);
      if (colored) {
        let color;
        if (parsedDate < new Date()) {
          color = red;
        } else {
          color = black;
        }
        return (<span style={{ color }}>{result}%</span>);
      }
      return result;
  }



  const getColoredNumber = (value: number) => {
      let color;
      if (value > 0) {
          color = green;
      } else if (value < 0) {
          color = red;
      } else {
          color = black;
      }
      return color
  }

const renderPercentage = (
    value: number | string | undefined,
    userNumberFormat: string,
    colored: boolean = false,
    minFractionDigits: number = 1,
    maxFractionDigits: number = 1,
    bold: boolean = false
) => {
    if (!value || value === na) return value
    value = typeof value === "string" ? parseFloat(value) : value;

    const share = (value || 0) * 100;
    let color = "inherit";

    if (colored) {
        color = getColoredNumber(share)
    }
    const fontWeight = bold ? "bold" : "normal";
    const formattedValue = `${share > 0 ? "+" : ""}${formatValue(share, userNumberFormat, minFractionDigits, maxFractionDigits)}`;
    return (<span style={{ fontWeight, color }}>{formattedValue}%</span>);
};

  const renderValueSpanColor = (value: number | undefined | null, colorSpans: ColorSpan[], lowerColor: string, replaceZero: boolean = false) => {
    if(value === undefined || value === null) {
      return na
    }
    const cSpan = colorSpans.find((cs) => cs.upperLimit < value);
    if(cSpan) {
      lowerColor = cSpan.color;
    }
    return (
      <span style={{ color: lowerColor }}>
        {replaceZero ? replaceZeroByNone(value) : value}
      </span>
    )
  };
  
  const getTypeImage = (value: number) => {
    const theme = useTheme();
    const color = theme.palette.mode === 'dark' ? theme.palette.grey.A100 : theme.palette.grey[600]
   return (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {value === 1 ? (
          <ShoppingCartIcon sx={{ width: 20, height: 20, color }} />
        ) : (
          <ViewComfyIcon sx={{ width: 20, height: 20, color }} />
        )}
      </Box>
    );
  };
  
  const getChip = (value: string | ReactNode, color: string) => {
    if (!value) {
      return value
    }
    return <Chip
      label={value}
      size="small"
      sx={{ backgroundColor: color, borderRadius: "100px", color: "#fff" }}/>
  }

  const formatValue = (
  value: number | string | Date,
  userNumberFormat: string,
  maximumFractionDigits: number = 2,
  minimumFractionDigits: number = 0,
  useTime: boolean = true,
  useHours: boolean = true
): string => {
  if (value === undefined || value === null) return na;

  if (typeof value === "number") {
    return new Intl.NumberFormat(userNumberFormat, {
      useGrouping: true,
      minimumFractionDigits,
      maximumFractionDigits,
    }).format(value);
  }

  if (value instanceof Date) {
    const formattedDate = new Intl.DateTimeFormat(userNumberFormat, {
      day: "numeric",
      month: "numeric",
      year: "numeric",
      ...(useTime
        ? {
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
            ...(useHours ? { hour: "2-digit", minute: "2-digit" } : {}),
          }
        : {}),
    }).format(value);

    return formattedDate.replace(",", "");
  }

  return String(value);
};

  return {
    formatValue,
    getTranslatedHeaderName,
    renderTranslatedCell,
    trimUrl,
    boolIntToString,
    replaceZeroByNone,
    renderLinkCell,
    renderFixedColor,
    formatDate,
    parseCustomDate,
    renderDate,
    renderValueSpanColor,
    getTypeImage,
    getChip,
    na,
    statusTranslations,
    avTranslations,
    yesNoTranslations,
    renderPercentage,
    removeProtocol
  };
};

export default useCustomTableUtils;
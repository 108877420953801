import { createMutationFunction } from "../mutations/createMutationFunction";
import { useApiMutation } from "../useApiMutation";
import { UsersTrafficParams, UsersTrafficResponse } from "./types";

export const useUsersTraffic = () => {
  return useApiMutation<UsersTrafficResponse[], UsersTrafficParams >({
    mutationFn: createMutationFunction<UsersTrafficResponse[], UsersTrafficParams>(
      "app/management/users/analyze_traffic"
    ),
  });
};
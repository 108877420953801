export interface PeriodOption {
  value: number;
  label: string;
}

export const createBillingCycleOptions = (t: (key: string) => string): PeriodOption[] => [
  { value: 1, label: `${1} ${t('general.month')}` },
  { value: 3, label: `${3} ${t('general.months')}` },
  { value: 6, label: `${6} ${t('general.months')}` },
  { value: 12, label: `${12} ${t('general.months')}` },
  { value: 24, label: `${24} ${t('general.months')}` },
  { value: 36, label: `${36} ${t('general.months')}` }
];

export const createNoticePeriodOptions = (t: (key: string) => string): PeriodOption[] => [
  { value: 1, label: `${1} ${t('general.month')}` },
  { value: 2, label: `${2} ${t('general.months')}` },
  { value: 3, label: `${3} ${t('general.months')}` },
  { value: 4, label: `${4} ${t('general.months')}` },
  { value: 5, label: `${5} ${t('general.months')}` },
  { value: 6, label: `${6} ${t('general.months')}` },
  { value: 9, label: `${9} ${t('general.months')}` },
  { value: 12, label: `${12} ${t('general.months')}` }
];

export const createMinTermOptions = (t: (key: string) => string): PeriodOption[] => [
  { value: 1, label: `${1} ${t('general.month')}` },
  { value: 2, label: `${2} ${t('general.months')}` },
  { value: 3, label: `${3} ${t('general.months')}` },
  { value: 4, label: `${4} ${t('general.months')}` },
  { value: 5, label: `${5} ${t('general.months')}` },
  { value: 6, label: `${6} ${t('general.months')}` },
  { value: 9, label: `${9} ${t('general.months')}` },
  { value: 12, label: `${12} ${t('general.months')}` },
  { value: 24, label: `${24} ${t('general.months')}` },
  { value: 36, label: `${36} ${t('general.months')}` },
  { value: 48, label: `${48} ${t('general.months')}` },
  { value: 60, label: `${60} ${t('general.months')}` }
];

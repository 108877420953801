import { useApiQuery } from "../useApiQuery";
import { createQueryFunction } from "../queries/createQueryFunction";
import { AccountResponse, AccountsApiType } from "./types";


export const useAccounts = (): AccountsApiType => {
    return useApiQuery<AccountResponse[], {}>({
      key: 'accounts',
      queryFn: createQueryFunction(`app/accounts`),
    });
};
import React, { useEffect, useMemo, useState } from "react";
import { Paper } from "@mui/material";
import { MuiGridTable } from "@/components/tables/DataGridTable";
import { useColumns } from "@/hooks/useColumnsData";
import ContentContainer from "@/components/contentContainer";
import CustomGridTreeDataGroupingCell from "@/components/CustomGridTreeDataGroupingCell";
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderEditCellParams,
  GridSortDirection,
} from "@mui/x-data-grid-pro";
import useData from "@/hooks/auth/useData";
import { t } from "i18next";
import { useTheme } from "@emotion/react";
import useCustomTableUtils from "@/hooks/useColumnsData/utils";
import CreateMarketModal from "./CreateMarketModal";
import { MarketsResponse, MarketsWithChildren } from "@/api/setup/types";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "./Menu";

export const Markets = () => {
  const { selectedManufacturers, setupMarketsApi } = useData();
  const allColumns = useColumns();
  const themes = useTheme();
  const { na } = useCustomTableUtils();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedRow, setSelectedRow] = useState<MarketsWithChildren | null>(
    null
  );

  const [openModal, setOpenModal] = useState(false);

  const manufacturerIds = useMemo(
    () => selectedManufacturers.map((m) => m.ID),
    [selectedManufacturers]
  );

  const rows = useMemo(() => {
    return (setupMarketsApi?.data || []).map((row) => ({
      ...row,
    }));
  }, [setupMarketsApi?.data]);

  const handleMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    row: MarketsResponse
  ) => {
    event.stopPropagation();
    const children = getChildren(row);
    setAnchorEl(event.currentTarget);
    setSelectedRow({ ...row, children });
  };

  const getChildren = (parentRow: MarketsResponse) => {
    if (!parentRow.hierarchy) return [];

    const parentHierarchy = parentRow.hierarchy;

    return rows.filter(
      (row) =>
        row.hierarchy &&
        row.hierarchy.length > parentHierarchy.length &&
        row.hierarchy.slice(0, parentHierarchy.length).join(",") ===
          parentHierarchy.join(",")
    );
  };

  useEffect(() => {
    if (!manufacturerIds.length) return;

    setupMarketsApi?.execute({
      m: manufacturerIds,
    });
  }, [manufacturerIds]);

  const groupingMarketColDef: GridColDef = {
    headerName: t("tableColumns.market") || "Market",
    headerClassName: "grouping-header",
    field: "name",
    width: 220,
    renderCell: (params: GridRenderEditCellParams) => (
      <CustomGridTreeDataGroupingCell
        {...params}
        content={params.row.type === "market" ? params.row.name || na : ""}
      />
    ),
    valueGetter: (_: any, row: Record<string, any>) =>
      row.type == "market" ? row.name || na : "",
  };

  const columns: Array<GridColDef> = [
    {
      field: "name",
      headerName: t("tableColumns.maker") || "Maker",
      minWidth: 150,
      valueGetter: (_: any, row: Record<string, any>) =>
        row.type == "product" ? row.name || na : "",
      flex: 1,
      colSpan: (_: any, row: Record<string, any>) => {
        if (row.type === "market") {
          return 5;
        }
        return 1;
      },
      renderCell: (params: GridRenderEditCellParams) => (
        <span
          style={{
            color:
              params.row.type == "product"
                ? themes.palette.text.primary
                : themes.palette.text.secondary,
          }}
        >
          {params.row.type == "product"
            ? params.row.name || na
            : params.row.comment}
        </span>
      ),
      sortable: false,
    },
    {
      ...allColumns.brandNameColumn,
      minWidth: 150,
      valueGetter: (_: any, row: Record<string, any>) =>
        row.type == "product" ? row.brand || na : " ",
      flex: 1,
      sortable: false,
    },
    {
      ...allColumns.productColumn,
      minWidth: 150,
      valueGetter: (_: any, row: Record<string, any>) =>
        row.type == "product" ? row.product || na : " ",
      flex: 1,
      sortable: false,
    },
    {
      ...allColumns.sizeColumn,
      minWidth: 80,
      valueGetter: (_: any, row: Record<string, any>) =>
        row.type == "product" ? row.size || na : " ",
      sortable: false,
    },
    {
      ...allColumns.pznColumn,
      minWidth: 80,
      valueGetter: (_: any, row: Record<string, any>) =>
        row.type == "product" ? row.pzn || na : " ",
      align: "right",
      sortable: false,
    },
    {
      ...allColumns.makerCollaborationColumn,
      valueGetter: (_: any, row: Record<string, any>) =>
        row.type == "market" ? row.collaboration || na : " ",
    },
    allColumns.timeAddedColumn,
    {
      field: "actions",
      type: "actions",
      width: 30,
      getActions: (params) => {
        if (params.row.type !== "market") {
          return [];
        }
        return [
          <GridActionsCellItem
            icon={<MoreVertIcon />}
            label="Actions"
            onClick={(event) => {
              handleMenuClick(event, params.row);
            }}
          />,
        ];
      },
    },
  ];

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const sortModel = useMemo(
    () => [
      {
        field: "created",
        sort: "desc" as GridSortDirection,
      },
    ],
    []
  );

  return (
    <ContentContainer>
      <Paper>
        <MuiGridTable
          filterBarType={2}
          rows={rows}
          columns={columns}
          loading={setupMarketsApi?.isLoading} // for data grid pro it does not work
          getTreeDataPath={(row) => row.hierarchy || []}
          sortModel={sortModel}
          treeData
          hasDelete={false}
          hasControls={true}
          onCreateClick={() => setOpenModal(true)}
          initialPageSize={100}
          groupingColDef={groupingMarketColDef}
          getRowClassName={(params) => {
            if (params.row.type === "product" || params.row.keyword) {
              return "gray-50-row";
            }
            return "";
          }}
          tableInfo={t("tableInfo.setupMarkets") || ""}
          searchPlaceholder={
            t("tableColumns.searchPlaceholder") || "SKU, Products, ..."
          }
        />
      </Paper>
      <CreateMarketModal
        listApi={setupMarketsApi}
        open={openModal}
        onSuccess={() => {}}
        onClose={() => {
          setOpenModal(false);
          setSelectedRow(null);
        }}
        selectedRow={selectedRow}
      />
      <Menu
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        onEditClick={() => {
          setOpenModal(true);
        }}
      />
    </ContentContainer>
  );
};

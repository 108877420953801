import React from "react";
import styled from "@emotion/styled";
import { IconButton, Tooltip, useTheme } from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";

const IconButtonStyled = styled(IconButton)<{
  drawerWidth: number;
  open: boolean;
}>`
  position: fixed;
  top: 52px;
  left: ${(props) => (props.open ? `${props.drawerWidth - 14}px` : "2px")};
  transition: 200ms cubic-bezier(0.2, 0, 0, 1) 0s, opacity 0.3s ease;
  z-index: 1300;

  opacity: ${(props) => (props.open ? 0 : 1)};

  &.toggleButton {
    opacity: ${(props) => (props.open ? 0 : 1)};
  }

  &:hover {
    background-color: #4a90e2;
    opacity: 1;
  }

  &:focus {
    outline: none;
  }

  &:active {
    background-color: #2a73ba;
  }

  @media (hover: none) and (pointer: coarse) {
    &:hover {
      background-color: unset;
    }
  }
`;

interface SidebarToggleButtonProps {
  drawerWidth: number;
  open: boolean;
  onClick: () => void;
}

const SidebarToggleButton: React.FC<SidebarToggleButtonProps> = ({
  drawerWidth,
  open,
  onClick,
}) => {
  const theme = useTheme();

  return (
    <Tooltip title={open ? "Collapse" : "Expand"} placement="right">
      <IconButtonStyled
        onClick={onClick}
        drawerWidth={drawerWidth}
        open={open}
        className="toggleButton"
        sx={{
          borderRadius: "100%",
          backgroundColor:
            theme.palette.mode === "dark"
              ? theme.palette.grey[50]
              : theme.sidebar.background,
          color:
            theme.palette.mode === "dark"
              ? theme.palette.common.black
              : theme.palette.common.white,
          padding: "0px",
          width: "24px",
          height: "24px",
        }}
      >
        {open ? (
          <KeyboardArrowLeft sx={{ width: "14px", height: "14px" }} />
        ) : (
          <KeyboardArrowRight sx={{ width: "14px", height: "14px" }} />
        )}
      </IconButtonStyled>
    </Tooltip>
  );
};

export default SidebarToggleButton;
